/* eslint-disable
    consistent-return,
    func-names,
    no-multi-assign,
    no-param-reassign,
    no-return-assign,
    no-var,
    prefer-const,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Provides 2 functions: get & put
// Currently all caching is done in reference level, no cloning or serialization is done, to make it fast.
// Originally written to cache HTTP GET requests, but now using superagent-cache for that purpose.
let Cache;
module.exports = (Cache = {});

const _ = require('lodash');
const Time = require('react/utils/time');
const log = require('react/utils/log')('Cache');
const localStorage = require('react/utils/local_storage');

const _storage = localStorage;
// structure:
// key:
//    updatedAt: Date
//    expiresAt: Date
//    value: Any

// Reason of not being available might be not having the storage, or storage being full.
Cache.isAvailable = () => _isStorageAvailable();

Cache.isFull = () => _isStorageFull();

Cache.getStorage = () => _storage;

// opts
// - expiration (in seconds). When undefined, no expiration. When defined, data
// will expire after given seconds.
// - namespace (*empty): Append a namespace prefix to the key.
Cache.put = function (key, value, opts) {
    if (opts == null) { opts = {}; }
    const updatedAt = new Date();
    const expiresAt = (() => {
        if (opts.expiration || (opts.expiration === 0)) {
            const t = new Date();
            t.setSeconds(t.getSeconds() + opts.expiration);
            return t;
        }
        return undefined;
    })();

    const json = JSON.stringify({
        updatedAt: Time.namedFormat(updatedAt, 'iso'),
        expiresAt: Time.namedFormat(expiresAt, 'iso'),
        value
    });

    try {
        return _storage[_getCacheKey(key, opts.namespace)] = json;
    } catch (e) {
        window.error = e;
        log("Couldn't cache (see window.error).");
        throw e;
    }
};


// If the key is not expired based on expiration setting when putting the value
// as well as expiration time given to the function, will get cached value,
// otherwise get undefined.
//
// opts
// - expiration (in seconds). When defined, will check expiration of given key
//   regardless of whether there's expiration setting when putting the value. When
//   both are present, will obey both expiration settings.
//
// - namespace (*empty): Append a namespace prefix to the key.
//
Cache.get = function (key, opts) {
    if (opts == null) { opts = {}; }
    const json = _storage[_getCacheKey(key, opts.namespace)];

    if (!json) { return undefined; }

    let { value, expiresAt: putExpiresAt, updatedAt } = JSON.parse(json);
    putExpiresAt = Time.parseNamedFormat(putExpiresAt, 'iso');
    updatedAt = Time.parseNamedFormat(updatedAt, 'iso');

    let expiresAt = putExpiresAt;

    if (opts.expiration || (opts.expiration === 0)) {
        const t = new Date(updatedAt);
        const getExpiresAt = t.setSeconds(t.getSeconds() + opts.expiration);
        expiresAt = _.min([expiresAt, getExpiresAt]);
    }

    const expired = expiresAt
        ? expiresAt < new Date()
        : false;

    if (expired) {
        return undefined;
    }
    return value;
};


Cache.remove = (key, namespace) => delete _storage[_getCacheKey(key, namespace)];


// Cleans up storage space. All expired items, as well as old items, will be removed.
//
// opts
// - percentage (*50): How much percentage of non-expired items to delete.
// - namespace (*empty): The namespace of keys to delete.
Cache.cleanupStorage = function (opts) {
    if (opts == null) { opts = {}; }
    opts = _.defaults(opts, {
        percentage: 50,
        namespace: ''
    });
    const { namespace, percentage } = opts;

    log(`cleanupStorage with opts: ${JSON.stringify(opts)}`);
    log(`${_.size(_storage)} items in storage`);

    const nonExpiredKeys2ExpiresAt = {};
    const expiredKeys = [];

    const now = new Date();
    const cacheKeyPrefix = _getCacheKeyPrefix(namespace);

    _.times(_storage.length, (idx) => {
        const key = _storage.key(idx);
        if (!_.startsWith(key, cacheKeyPrefix)) { return; }

        let { expiresAt } = JSON.parse(_storage[key]);
        expiresAt = Time.parseNamedFormat(expiresAt, 'iso');

        if (expiresAt < now) {
            return expiredKeys.push(key);
        }
        return nonExpiredKeys2ExpiresAt[key] = expiresAt;
    });

    const expiredCount = _.size(expiredKeys);
    const count = _.size(nonExpiredKeys2ExpiresAt);

    log(`${expiredCount + count} keys under namespace '${namespace}'`);

    _.each(expiredKeys, (key) => delete _storage[key]);
    log(`Deleted ${expiredCount} expired items, ${count} items not expired`);

    const toDeleteCount = _.round((count * percentage) / 100);
    const toDelete = _(_.toPairs(nonExpiredKeys2ExpiresAt)).sortBy('1').slice(0, toDeleteCount);

    log(`${toDeleteCount} items to delete (${percentage}%)`);
    _(toDelete).each((tpl) => delete _storage[tpl[0]]);

    return log(`${_.size(_storage)} items remaining in storage`);
};


// Depending on browser, the storage required may not be available.
var _isStorageAvailable = () => (_storage != null) && !_isStorageFull();

// Result is only meaningful when _storage is there.
var _isStorageFull = function () {
    try {
        const x = '__storage_test__';
        _storage.setItem(x, x);
        _storage.removeItem(x);
        return false;
    } catch (e) {
        return true;
    }
};


var _getCacheKeyPrefix = (namespace) => `smk-cache-${namespace}`;

var _getCacheKey = function (originalKey, namespace) {
    if (namespace == null) { namespace = ''; }
    return `${_getCacheKeyPrefix(namespace)}-${originalKey}`;
};
