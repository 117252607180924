/* eslint-disable
    func-names,
    no-multi-assign,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Calc;
module.exports = (Calc = {});
const _ = require('lodash');
const Assert = require('utils/assert');

// arrays: [ array ]
//   array: [ val ]
//
// result: [ sum(val) ]
//
// Example:
//   Given [ [1,2,3], [4,5,6] ],
//   Return [5,7,9]
//
//   Given []
//   Return undefined
//
// opts
// - includePartialSums:
//   - If true, will do partial sum when nils (null or undefined) are present
//   - Else, will return null when nils are present
Calc.sumArrays = function (arrays, opts) {
    const { includePartialSums } = _.defaults({}, opts, { includePartialSums: true });

    Assert.type(arrays, _.isArray);

    if (arrays.length === 0) {
        return undefined;
    }

    const areLengthsSame = _(arrays).map('length').uniq().size() === 1;
    if (!areLengthsSame) { throw Error("Lengths aren't same"); }

    const sumArray = _(arrays[0].length).times((idx) => {
        const nums = _(arrays).map(idx);
        if (nums.some(_.isNil) && !includePartialSums) {
            return null;
        }
        return nums.sum();
    });

    return sumArray;
};


Calc.median = function (values) {
    if (_.isEmpty(values)) { return undefined; }
    const numericValues = _.filter(values, (val) => _.isNumber(val) && !_.isNaN(val));
    const sortedValues = _.sortBy(numericValues);
    const middleIdx = (sortedValues.length + 1) / 2;

    if ((sortedValues.length % 2) === 1) {
        return sortedValues[middleIdx - 1];
    }
    return (sortedValues[middleIdx - 1.5] + sortedValues[middleIdx - 0.5]) / 2;
};


// cents?: number
Calc.cent2dollar = function (cents) {
    if (cents != null) { return cents / 100; } return null;
};
