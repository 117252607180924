/* eslint-disable
    func-names,
    no-multi-assign,
    no-param-reassign,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let ErrorReport;
module.exports = (ErrorReport = {});

const Slack = require('react/utils/slack');
const Login = require('react/utils/login');
const Tracker = require('react/utils/tracker.js').default;

ErrorReport.report = function (error, request, response) {
    if (request == null) { request = undefined; }
    if (response == null) { response = undefined; }
    Slack.postErrorToInstrumentChannel(error, Login.loggedInUser, window.location.href);
    return Tracker.log(error, Login.loggedInUser, window.location.href, request, response);
};
