/* eslint-disable
    consistent-return,
    func-names,
    no-multi-assign,
    no-return-assign,
    no-unused-vars,
    no-var,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Login;
module.exports = (Login = {});

const Http = require('react/utils/http');
const Track = require('react/utils/track');
const Intercom = require('react/utils/intercom');
const localStorage = require('react/utils/local_storage');
const LocalSettings = require('react/utils/local_settings');

Login.loggedInUser = null;

// Calls backend to get login token, sets token, and runs callback.
Login.login = function (email, password, callback) {
    Track.attemptedLogin(email);
    return Http.post('/api/users/v1/login', { email, password }, { forceCallback: true }, (data, errmsg) => {
        if (data) {
            Login.setLoginToken(data.token);
            Track.identify(email);
            Track.loggedIn(email);
            return callback();
        }
        return callback(errmsg);
    });
};

Login.setupUser = function (user) {
    Track.setPeople(user);
    Login.loggedInUser = user;
    const shouldBootIntercom = !Login.isImpersonated() && !user.isExternalWidget;
    if (user.superAdmin) {
        Http.CACHE_METHOD = LocalSettings.get('http.cache_method') || 'none';
    }
    if (shouldBootIntercom) { return Intercom.boot(user); }
};

Login.getToken = () => getNgStorage('token');

Login.isLoggedIn = function () {
    return (this.getToken() != null);
};

Login.logout = function () {
    Http.cleanupCache();
    if (!Login.isImpersonated()) { Intercom.shutdown(); }
    Login.stopImpersonation();
    return _clearLoginToken();
};

Login.isImpersonated = () => getNgStorage('impersonatorToken') != null;


Login.impersonate = (user, callback) => Http.post(`/api/users/v1/impersonate/${user.email}`, {}, (result) => {
    _setImpersonatedToken(result.token);
    // Clear logged in user, so that after redirect, impersonated user info can be loaded from backend.
    Login.loggedInUser = null;
    Intercom.shutdown();
    return callback();
});


Login.stopImpersonation = function () {
    _clearImpersonatedToken();
    return Login.loggedInUser = null;
};

//= ===================================
// Token setting & clearing
//= ===================================
Login.setLoginToken = function (token) {
    setNgStorage('token', token);
    return delete localStorage[getNgStorageKey('impersonatorToken')];
};

var _clearLoginToken = (token) => delete localStorage[getNgStorageKey('token')];

var _setImpersonatedToken = function (token) {
    setNgStorage('impersonatorToken', getNgStorage('token'));
    return setNgStorage('token', token);
};

var _clearImpersonatedToken = function () {
    setNgStorage('token', getNgStorage('impersonatorToken'));
    return delete localStorage[getNgStorageKey('impersonatorToken')];
};


const NG_STORAGE_PREFIX = 'ngStorage-';

// LATER TP Change it when angular is gone
var getNgStorageKey = (origKey) => `${NG_STORAGE_PREFIX}${origKey}`;

var getNgStorage = function (key) {
    const val = localStorage[getNgStorageKey(key)];
    if (val) {
        return JSON.parse(val);
    }
    return undefined;
};

var setNgStorage = function (key, value) {
    const val = JSON.stringify(value);
    if (val) {
        return localStorage[getNgStorageKey(key)] = val;
    }
};
