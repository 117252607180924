/* eslint-disable
    func-names,
    no-multi-assign,
    no-param-reassign,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Str;
module.exports = (Str = {});
const _ = require('lodash');
const Misc = require('utils/misc');

// patterns: pattern | [ pattern ]
//   pattern: string | regex
//
// result: bool. True when str matches any given pattern. False otherwise.
//
// When pattern is a string, it's matched in its entirety.
// When it's regex, match with regex.
Str.matches = function (str, patterns) {
    patterns = Misc.makeArray(patterns);

    return _.some(patterns, (pattern) => {
        if (_.isRegExp(pattern)) {
            return str.match(pattern);
        }
        return str === pattern;
    });
};

// Safety wrapper around native .match method to safeguard against invalid regex string patterns.
// Use this function for any user-facing regex-enabled search functionality.
// WARNING: avoid using this function for all regex matching in order to fail fast.
Str.match = function (str, patternStr) {
    try {
        const pattern = new RegExp(patternStr, 'i');
        return str.match(pattern);
    } catch (error) {
        return null;
    }
};
