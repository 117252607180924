// @flow
import _ from 'lodash';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import type { User } from 'shared/FlowTypes';
import { LOADED_USER, LOGGED_OUT } from './actions';


function user(user: ?User = null, action: {}) {
    switch (action.type) {
    case LOADED_USER:
        return action.user;
    case LOGGED_OUT:
        return null;
    default:
        return user;
    }
}

export default (history) => combineReducers({
    router: connectRouter(history),
    user
});
