/* eslint-disable
    func-names,
    no-multi-assign,
    no-shadow,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let TimeRangeOptions;
const _ = require('lodash');

module.exports = (TimeRangeOptions = (function () {
    let getValidTimeRange;
    TimeRangeOptions = class TimeRangeOptions {
        static initClass() {
            getValidTimeRange = function (timeRangeOptions, timeRangeStr, fallback) {
                const foundTimeRangeOption = _.find(timeRangeOptions, { value: timeRangeStr });
                if ((foundTimeRangeOption != null) && !foundTimeRangeOption.disabled) {
                    return timeRangeStr;
                }
                return fallback(timeRangeOptions);
            };
        }

        static getValidTimeRangeOrUndefined(timeRangeOptions, timeRangeStr) {
            return getValidTimeRange(timeRangeOptions, timeRangeStr, () => undefined);
        }

        static getValidTimeRangeOrFirstEnabledTimeRange(timeRangeOptions, timeRangeStr) {
            return getValidTimeRange(timeRangeOptions, timeRangeStr, this.getFirstEnabledTimeRange);
        }

        static getFirstEnabledTimeRange(timeRangeOptions) {
            const firstEnabledOption = _.find(timeRangeOptions, (opt) => !opt.disabled);
            if (firstEnabledOption != null) {
                return firstEnabledOption.value;
            }
            return undefined;
        }
    };
    TimeRangeOptions.initClass();
    return TimeRangeOptions;
}()));
