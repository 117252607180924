/* eslint-disable
    func-names,
    max-len,
    no-multi-assign,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let _combinePastFuture;
const _ = require('lodash');

// Combine the past and future data into time series.
//
// If past/future data do not exist, we fill them with 0's/nulls respectively.
//
// Params:
//   - pastGroups/future: [{ group: 'Name', value: [val] }]
//   - groupName: group name to be combined
//   - pastHours/futureHours: Int
//     Used when some group is missing and needs to fill in values.
//
// Return [val], concatenated from the past and future value arrays
//
module.exports = (_combinePastFuture = function (pastGroups, futureGroups, groupName, pastHours, futureHours) {
    const [pastGroup, futureGroup] = Array.from(_.map([pastGroups, futureGroups], (groups) => _.find(groups, { group: groupName })));

    // For a group, if it's missing in past but not in prediction, then it means
    // all past data are 0. But if it's missing in prediction but not in past,
    // then it means we're uncertain about the future.
    const pastArray = (pastGroup != null ? pastGroup.value : undefined) || _.fill(Array(pastHours), 0);
    const futureArray = (futureGroup != null ? futureGroup.value : undefined) || _.fill(Array(futureHours), null);

    return _.concat(pastArray, futureArray);
});
