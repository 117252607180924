/* eslint-disable
    consistent-return,
    func-names,
    max-len,
    no-multi-assign,
    no-param-reassign,
    no-return-assign,
    no-shadow,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let DataGroups;
const _ = require('lodash');
const Assert = require('utils/assert');
const Calc = require('utils/calc');
const Granularity = require('utils/granularity');
const naturalCompare = require('string-natural-compare');

// The DataGroups class holds series of data that are broken down into groups.
//
// Usage:
//
// dg = DataGroups(groups, totalGroup?)
//   groups: [ group ]
//   totalGroup?: group
//     group: { name: 'Name', values: [ Value ] }
//       Value: Number | Object
//
// Note: `groups` should not include total group. The name 'Total' is reserved for the total group.
//
// Properties:
//   - groups: same as the input
//   - totalGroup: same as the input
//
// Derived properties (can be referenced by user of this class):
//   - allGroups: all groups, including totalGroup (if defined)
//   - nPoints: number of points in the groups. We verify that all groups have the same length at setup.
//
// Remeber to update SmkPT.DataGroups if DataGroups changes
module.exports = (DataGroups = (function () {
    DataGroups = class DataGroups {
        static initClass() {
            this.TOTAL_GROUP_NAME = 'Total';
        }

        // TODO TP (2017-03-25 15:26): Ensure total group has 'Total' name
        constructor(groups, totalGroup) {
            this.groups = groups;
            if (totalGroup == null) { totalGroup = undefined; }
            this.totalGroup = totalGroup;
            this._setDerivedProps();
            this._verifyProps();
        }

        _setDerivedProps() {
            this.allGroups = this._getAllGroups();
            if (this.allGroups.length === 0) {
                return this.nPoints = 0;
            }
            return this.nPoints = this.allGroups[0].values.length;
        }

        _getAllGroups() {
            if (this.totalGroup != null) {
                if (this.groups.length === 0) {
                    return [this.totalGroup];
                } if (this.groups.length === 1) {
                    return this.groups;
                }
                return _.concat([this.totalGroup], this.groups);
            }
            return this.groups;
        }

        _verifyProps() {
            // @_verifyNoTotalGroupIncludedInGroups()
            return this._verifyAllGroupsHaveSameLength();
        }

        _verifyNoTotalGroupIncludedInGroups() {
            if (_.includes(this.getGroupNames(), this.constructor.TOTAL_GROUP_NAME)) {
                throw RangeError('`groups` should not include total groups.');
            }
        }

        _verifyAllGroupsHaveSameLength() {
            return _.each(this.allGroups, (g) => {
                const points = g.values.length;
                if (points !== this.nPoints) {
                    throw Error(`Inconsistent group lengths. Expect ${this.nPoints} points but got ${points} for '${g.name}' group.`);
                }
            });
        }

        // TODO (MK): Use clone only when necessary. It has negative impacts on front-end performance.
        clone() {
            const [groups, totalGroup] = Array.from(_.map([this.groups, this.totalGroup], _.cloneDeep));
            return new DataGroups(groups, totalGroup);
        }

        getGroupNames() { return _.map(this.groups, 'name'); }

        getAllGroupNames() { return _.map(this.allGroups, 'name'); }

        // Set totalGroup as the sum of all `@groups`.
        //
        // For each point, if any corresponding points in `groups` is null or
        // undefined, if opts.includePartialSums is true, do a partial sum,
        // otherwise set the point to null.
        //
        // opts: { includePartialSums? }
        //
        setTotalGroupAsSumOfGroups(opts) {
            if (opts == null) { opts = {}; }
            const { includePartialSums } = _.defaults({}, opts, { includePartialSums: false });

            this._verifyProps();
            let totalValues = Calc.sumArrays(_.map(this.groups, 'values'), { includePartialSums });
            if (!totalValues) { totalValues = []; }

            this.totalGroup = {
                name: this.constructor.TOTAL_GROUP_NAME,
                values: totalValues
            };

            return this.allGroups = this._getAllGroups();
        }

        // opts: { includePartialSums? }
        setTotalGroupAsSumOfGroupsIfGroupsExist(opts) {
            if (opts == null) { opts = {}; }
            if (this.nPoints > 0) {
                return this.setTotalGroupAsSumOfGroups(opts);
            }
        }


        // Convert values in groups to another granularity, aggregated by
        // aggFunction.
        //
        // Since when constructing dataGroups we don't specify the granularity,
        // need to specify fromGranularity here.
        //
        // aggFunc: ( [ val ] ) -> aggVal.
        //
        // If fromGranularity is equal to toGranularity, return original dataGroups.
        // If fromGranularity (say Monthly) is larger than toGranularity (say
        // Daily), throw error.
        //
        // startDate & bizStartHour are used to put data into correct "bucket".
        //
        // result: { aggDataGroups, aggStartDate }
        //   aggDataGroups: DataGroups where values of each group is aggregated.
        //   aggStartDate: The starting Date for the first timespan.
        //
        // LATER TP: When actual aggregation is needed, currently only aggregates from
        // hourly data to other granularity. Implement the extra aggregations.
        //
        aggregateToGranularity(fromGranularity, toGranularity, aggFunc, startDate, bizStartHour) {
            let aggTotalGroup;
            if (fromGranularity === toGranularity) {
                return { aggStartDate: startDate, aggDataGroups: this };
            }
            const fromSecs = Granularity.getSeconds(fromGranularity);
            const toSecs = Granularity.getSeconds(toGranularity);
            if (fromSecs > toSecs) {
                throw Error('Cannot aggregate into smaller granularity!');
            }
            if (fromGranularity !== 'Hourly') {
                throw Error("Haven't implemented yet!");
            }

            let aggStartDate;
            const aggGroups = _.map(this.groups, (group) => {
                let data;
                ({ data, startDate: aggStartDate } = Granularity.groupBy(toGranularity, startDate, group.values, aggFunc, bizStartHour));

                return {
                    name: group.name,
                    values: data
                };
            });

            if (this.totalGroup) {
                const { data } = Granularity.groupBy(toGranularity,
                    startDate, this.totalGroup.values, aggFunc, bizStartHour);
                aggTotalGroup = { name: this.totalGroup.name, values: data };
            }

            return { aggStartDate, aggDataGroups: new DataGroups(aggGroups, aggTotalGroup) };
        }

        // condition: Same as _.find
        // result: group?
        findGroup(condition) {
            return _.find(this.allGroups, condition);
        }

        indexOf(group) {
            return _.indexOf(this.allGroups, group);
        }

        hasGroups() {
            return this.allGroups.length > 0;
        }

        // Goes over each <value> in each group.values, use result of func(value) to
        // construct a new DataGroups. The new DataGroups have exact same groups as
        // old one, but with new values.
        //
        // func: (val, valIdx) -> newVal
        //   valIdx is the index of val in group.values array.
        // result: DataGroups
        mapValues(func) {
            const newAllGroups = _.map(this.allGroups, (g) => _.extend({}, g, { values: _.map(g.values, func) }));
            return DataGroups.createFromAllGroups(newAllGroups);
        }

        // Goes over each group, use result of func(group.values) to construct a new
        // group. Result groups are used to build a new DataGroups.
        //
        // func: (group.values) -> newValues
        // result: DataGroups
        mapGroupValues(func) {
            const newAllGroups = _.map(this.allGroups, (g) => {
                const newVals = func(g.values);
                Assert.type(newVals, _.isArray);
                return _.extend({}, g, { values: newVals });
            });
            return DataGroups.createFromAllGroups(newAllGroups);
        }

        mapGroups(func) {
            const newAllGroups = _.map(this.allGroups, func);
            return DataGroups.createFromAllGroups(newAllGroups);
        }

        // Return a new DataGroups, with groups sorted by <sortBy> argument (same
        // usage as lodash sortBy). totalGroup position will not be affected.
        //
        // Example: sortGroups('name') -> Groups will be sorted by name.
        sortGroups() {
            // sortedAllGroups = _.sortBy @allGroups, 'name'
            const sortedAllGroups = this.allGroups.sort((a, b) => naturalCompare(a.name, b.name));

            return DataGroups.createFromAllGroups(sortedAllGroups);
        }

        // Given all groups (potentially including totalGroup), construct DataGroups.
        static createFromAllGroups(allGroups) {
            const totalGroup = _.find(allGroups, { name: this.TOTAL_GROUP_NAME });
            const groups = _.without(allGroups, totalGroup);
            return new DataGroups(groups, totalGroup);
        }


        // Check if a group is total group
        static isTotal(group) {
            return group.name === this.TOTAL_GROUP_NAME;
        }

        static buildTotalGroup(values) {
            return { name: this.TOTAL_GROUP_NAME, values };
        }
    };
    DataGroups.initClass();
    return DataGroups;
}()));
