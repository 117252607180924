// @flow
import 'shared/assets/fonts/fonts.css';
import 'font-awesome/css/font-awesome.min.css';
import 'shared/semantic/dist/semantic.min.css';
import './index.styl';

import React from 'react';
import ReactDom from 'react-dom';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';

import ErrorReport from 'react/utils/error_report';
import createRootReducer from './LoggedIn/reducer';
import App from './App';

main();

function main() {
    const history = createBrowserHistory();
    const store = getStore(history);

    if (process.env.NODE_ENV === 'development' && module.hot) {
        setupHotModuleReload(history, store);
    }
    if (process.env.NODE_ENV === 'production') {
        setupErrorHandling();
    }
    render(history, store);
}

function getStore(history) {
    return createStore(
        createRootReducer(history),
        compose(
            applyMiddleware(
                routerMiddleware(history),
                thunkMiddleware
            )
        )
    );
}

function setupHotModuleReload(history, store) {
    module.hot.accept('./LoggedIn/reducer', () => {
        store.replaceReducer(createRootReducer(history));
    });
    module.hot.accept('./App', () => {
        try {
            render(history, store);
        } catch (err) {
            console.error(err);
        }
    });
}

function setupErrorHandling() {
    // https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
    window.onerror = (messageOrEvent, source, lineno, colno, error) => {
    // This interception of window.onerror interferes with New Relic's error handling, so it's necessary to manually track errors for New Relic
        ErrorReport.report(error);
        throw error;
    };
}

function render(history, store) {
    return ReactDom.render(
        (
            <Provider store={store}>
                <App history={history} />
            </Provider>
        ),
        document.getElementById('smarking-app')
    );
}
