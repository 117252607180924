import Login from "react/utils/login";
import localStorage from "react/utils/local_storage";
import ThemeUtils from "shared/utils/ThemeUtils";


export default class Localization {

  static locationCurrency: string | null = null;
  static setLocationCurrency = (currencyCode: string) => {
    Localization.locationCurrency = currencyCode;
  }
  static getCurrencyForUser = () => {
    const currency = Login.loggedInUser ? Login.loggedInUser.currency : 'USD';
    return currency && Localization.currencies[currency];
  };

  static getRegion() {
    if (navigator.language === 'en-GB') {
      return 'en-GB';
    }
    return 'en-US';
  };

  static getCurrencyFromBrowserRegion() {
    const region = Localization.getRegion();
    const currencies = Object.entries(this.currencies).find(([key, value]) => value.region === region);
    return currencies && currencies[0];
  };

  static localStorageCurrencyKey = 'smk-currency';

  static getCurrencyCodeFromLocalStorage() {
    return localStorage && localStorage.getItem(this.localStorageCurrencyKey);
  }


  static setCurrencyCodeToLocalStorage(currencyCode: string) {
    localStorage.setItem(this.localStorageCurrencyKey, currencyCode);
  }

  static resetLocalCurrency() {
    localStorage.removeItem(this.localStorageCurrencyKey);
  }

  static getCurrencyFromLocalStorage() {
    const currencyCode = Localization.getCurrencyCodeFromLocalStorage();
    return currencyCode && Localization.currencies[currencyCode];
  }

  static getCurrency() {
    const userCurrency = Localization.getCurrencyFromLocalStorage()
      || Localization.getCurrencyForUser()
    ;
    if (userCurrency) {
      if (userCurrency.code === 'LOCATION') {
        if (Localization.locationCurrency) {
          return Localization.currencies[Localization.locationCurrency];
        }
      } else {
        return userCurrency;
      }
    }
    // if the userCurrency is LOCATION and there is no locationCurrency, return the theme or browser currency

    return Localization.getGetCurrencyFromTheme()
      || Localization.getCurrencyFromBrowserRegion()
      || Localization.currencies['USD']
      ;
  }

  static getGetCurrencyFromTheme() {
    return ThemeUtils.getCurrentTheme() == 'justpark' ? Localization.currencies['GBP'] : Localization.currencies['USD'];
  }

  static getCurrencySymbol() {
    return Localization.getCurrency().symbol;
  };

  static getCurrencyCode() {
    return Localization.getCurrency().code;
  };

  static getLocationCurrencySymbol() {
    const currencyCode = Localization.locationCurrency ? Localization.locationCurrency : 'USD';
    const currency = Localization.currencies[currencyCode];
    return currency.symbol;
  }

  static currencies = {
    'CAD': {
      region: 'en-CA',
      symbol: 'CA$',
      code: 'CAD'
    },
    'EUR': {
      locale: 'en-EUR',
      symbol: '€',
      code: 'EUR',
    },
    'GBP': {
      locale: 'en-GB',
      symbol: '£',
      code: 'GBP',
    },
    'USD': {
      locale: 'en-US',
      symbol: '$',
      code: 'USD',
    },
    'LOCATION': {
      code: 'LOCATION',
    }
  };
}
