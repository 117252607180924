/* eslint-disable
    consistent-return,
    default-case,
    func-names,
    no-multi-assign,
    no-param-reassign,
    no-return-assign,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Format;
module.exports = (Format = {});
const _ = require('lodash');
const numeral = require('numeral');
const locales = require('numeral/locales');
const Localization = require('shared/utils/localization').default;

//= ===============================================================================
// Number formatting
//= ===============================================================================
const _nonFormattableNumbers = [Infinity, NaN];

Format.isFormattable = (obj) => _.isNumber(obj) && !_.includes(_nonFormattableNumbers, obj);


// fallbackStr: If amount cannot be formatted, fallback text.
Format.currency = function (amount, symbol, fractionSize, fallbackStr) {
  if (symbol !== '') {
    symbol = '$';
  }
  if (fractionSize == null) {
    fractionSize = 0;
  }
  if (fallbackStr == null) {
    fallbackStr = 'N/A';
  }
  if (!Format.isFormattable(amount)) {
    return fallbackStr;
  }
  let str = `$0,0`;
  if (fractionSize > 0) {
    str += `.${_.fill(Array(fractionSize), 0).join('')}`;
  }
  let ret = numeral(amount).format(str);
  ret = _getCommaCurrency(ret, symbol);
  return ret;
};

function _getCommaCurrency(formattedNumber, currencySymbol) {
  if (currencySymbol === '$')
    return formattedNumber;

  return formattedNumber.replace('$', currencySymbol);
}

Format.shortCurrency = function (amount, inCurrencySymbol) {
  const currencySymbol = inCurrencySymbol || '$';
  if ((amount == null)) {
    return 'N/A';
  }
  const str = _.toString(_.round(amount));
  switch (_.trim(str, '-').length % 3) {
    case 0:
      return _getCommaCurrency(numeral(amount).format('($000a)'), currencySymbol);
    case 1:
      return _getCommaCurrency(numeral(amount).format('($0.00a)'), currencySymbol);
    case 2:
      return _getCommaCurrency(numeral(amount).format('($00.0a)'), currencySymbol);
  }
};


// fallbackStr: If amount cannot be formatted, fallback text.
Format.number = function (amount, fractionSize, fallbackStr) {
  if (fractionSize == null) {
    fractionSize = 0;
  }
  if (fallbackStr == null) {
    fallbackStr = 'N/A';
  }
  return Format.currency(amount, '', fractionSize, fallbackStr);
};

// fallbackStr: If amount cannot be formatted, fallback text.
Format.percentage = function (amount, fractionSize, fallbackStr) {
  if (fractionSize == null) {
    fractionSize = 0;
  }
  if (fallbackStr == null) {
    fallbackStr = 'N/A';
  }
  if (!Format.isFormattable(amount)) {
    return fallbackStr;
  }
  let str = '0';
  if (fractionSize > 0) {
    str += `.${_.fill(Array(fractionSize), 0).join('')}`;
  }
  str += '%';
  return numeral(amount).format(str);
};

Format.ensureNumber = function (value) {
  if (_.isString(value)) {
    return _.toNumber(value);
  }
  return value;
};

//= ===============================================================================
// String formatting
//= ===============================================================================
// Very simple pluralization, that saves a lot of space to remember rules
// Only used in cases that we know the pluralization rules in advance.
// Discuss with Tony for changes.
//
// opts
// - withCount: Add the count before word
Format.pluralize = function (word, count, opts) {
  if (opts == null) {
    opts = {};
  }
  opts = _.defaults(opts,
    {withCount: false});

  word = count === 1
    ? word
    : `${word}s`;

  if (opts.withCount) {
    word = `${count} ${word}`;
  }

  return word;
};


Format.strOrDefault = function (str, defaultStr) {
  if (!str) {
    str = '';
  }
  const trimmedStr = str.trim();
  if (trimmedStr === '') {
    return defaultStr;
  }
  return trimmedStr;
};


Format.str2numberOrNull = function (str) {
  if ((str === '') || _.isNil(str)) {
    return null;
  }
  const number = _.toNumber(_.toString(str).replace(/,|\$/g, ''));
  if (_.isNaN(number)) {
    return null;
  }
  return number;
};


//= ===============================================================================
// CSV formatting
//= ===============================================================================
Format.escapeStrForCsv = function (str) {
  if (_.includes(str, ',') || _.includes(str, '\n') || _.includes(str, '"')) {
    return `"${str.replace(new RegExp('"', 'g'), '""')}"`;
  }
  return str;
};

// data: [ rowData ]
//   rowData: [ cellValue ] or singleValue (will be converted to array)
//
// Result: CSV string.
//
// Will use max row column size as the column size of entire csv.  For rows with
// less column count than maximum, will pad with commas.
//
Format.generateCsv = function (data, opts) {
  if (opts == null) {
    opts = {};
  }
  if (!_.isArray(data)) {
    throw Error('Not supported yet');
  }

  const validData = _.map(data, (rowData) => _.concat([], rowData)); // Ensure each row is an array

  const maxCols = _(validData).map((rowData) => rowData.length).max();

  const rowsStr = _.map(validData, (rowData) => {
    let str = _(rowData)
      .map((cellValue) => Format.escapeStrForCsv(_.toString(cellValue)))
      .join(',');
    str += _.repeat(',', maxCols - rowData.length);
    return str;
  });

  return rowsStr.join('\n');
};


Format.generateCsvForExcel = function (data, opts) {
  if (opts == null) {
    opts = {};
  }
  if (!_.isArray(data)) {
    throw Error('Not supported yet');
  }

  // If the first cell's contents == 'ID', Excel will assume the file is a SYLK file format and throw an error.
  // Changing the first cell to anything but 'ID' (case-sensitive) will fix this problem.
  const dataClone = _.cloneDeep(data); // avoid mutating inputs
  if ((dataClone[0] != null ? dataClone[0][0] : undefined) === 'ID') {
    dataClone[0][0] = 'id';
  }

  return Format.generateCsv(dataClone, opts);
};

//= ===============================================================================
// Misc formatting
//= ===============================================================================
Format.phone = function (str) {
  if (str.length === 10) { // Valid length
    return str.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3');
  }
  return str; // Display invalid phone as it is
};

Format.commaOrSpaceSeparatedStringToArray = function (str) {
  str = _.replace(str, / /g, ',');
  let array = _.split(str, ',');
  return array = _.filter(array, (v) => v.length > 0);
};
