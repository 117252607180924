/* eslint-disable
    func-names,
    max-len,
    no-param-reassign,
    no-return-assign,
    no-unused-vars,
    no-var,
    react/forbid-foreign-prop-types,
    react/no-deprecated,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require('lodash');
const ReactUtil = require('react/utils/react_util');
const createReactClass = require('create-react-class');

const React = require('react');
// Monkey patch React.DOM to allow upgrade to React 16.
// This is no longer needed once we completely move off coffeescript.
React.DOM = require('react-dom-factories');

exports.React = React;

exports.SmkPT = require('./smk_prop_types');
exports.PT = require('prop-types');

// Makes a few enhancements over a basic react component (created with createReactClass):
//
// - Wraps the react component with "React.createFactory", so it can be used
//   directly (such as "App(props, children)"), instead of
//   "React.createElement(App, props, children)".
//
// - Appends <propTypes> and <defaultProps> to the created factory (if they
//   exist in the given spec), so they can be referenced outside of the component.
//
// - If <defaultProps> is provided in the spec, then will add <getDefaultProps>
//   function to the component. Will throw error if both <defaultProps> and
//   <getDefaultProps> are present.
//
// - Appends shouldComponentUpdate function to every component, if it's not
//   already defined within the component. By default does shallow comparison
//   for props and state, but the behavior can be modified by providing
//   spec.shouldComponentUpdateSpec. See _getShouldComponentUpdate().
exports.ReactComponentFactory = function (spec) {
    const { propTypes, defaultProps, getDefaultProps, consts, shouldComponentUpdateSpec } = spec;

    if ((defaultProps != null) && (getDefaultProps != null)) {
        throw Error("Shouldn't have both defaultProps and getDefaultProps()!");
    }

    if (defaultProps != null) {
        spec.getDefaultProps = () => defaultProps;
    }

    if (spec.shouldComponentUpdate == null) { spec.shouldComponentUpdate = _getShouldComponentUpdate(shouldComponentUpdateSpec, propTypes); }
    const factory = React.createFactory(createReactClass(spec));
    return _.extend(factory, { propTypes, defaultProps, consts });
};


// Simple wrapper around ReactUtil.shouldComponentUpdateBySpec.
// Return shouldComponentUpdate function.
//
// spec:
//   disableLogs: Bool.
//   compareAllProps: Bool.
//   - If false, will only use propType's keys for prop comparison.
//   - If true, will use all prop keys for prop comparison.
//
//   showChangedValues: Bool. When true, will print props and states that are
//     different from previous values.
//
//   For other specs, see ReactUtil.shouldComponentUpdateBySpec
var _getShouldComponentUpdate = function (spec, propTypes) {
    let shouldComponentUpdate;
    if (spec == null) { spec = {}; }
    if (propTypes == null) { propTypes = {}; }
    const { disableLogs, compareAllProps, showChangedValues } = _.defaults({}, spec, {
        disableLogs: false,
        compareAllProps: false,
        showChangedValues: false
    });

    if (!compareAllProps) {
        if (spec.onlyProps == null) { spec.onlyProps = _.keys(propTypes).concat('children'); }
    }

    return shouldComponentUpdate = function (newProps, newState) {
        const { should } = ReactUtil.shouldComponentUpdateBySpec(this, newProps, newState, spec);
        return should;
    };
};
