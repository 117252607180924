/* eslint-disable
    func-names,
    no-multi-assign,
    no-param-reassign,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Group;
module.exports = (Group = {});

const _ = require('lodash');
const Assert = require('utils/assert');
const Calc = require('utils/calc');

// Given a list of groups, sum them as one group
//
// groups: [ { group: groupName, value: [ int ] } ]
// result: { group: opts.name, value: [ sum(int) ] }
//
// opts
// - name: Summed group name
// - includePartialSums:
//   - If true, will do partial sum when nils (null or undefined) are present
//   - Else, will return null when nils are present
//
Group.sumAsOneGroup = function (groups, opts) {
    if (opts == null) { opts = {}; }
    const { name, includePartialSums } = _.defaults({}, opts,
        { name: 'Total', includePartialSums: true });

    Assert.type(groups, _.isArray);

    if (groups.length === 0) {
        return { group: name, value: [] };
    }

    const sumValue = Calc.sumArrays(_.map(groups, 'value'), { includePartialSums });

    return { group: name, value: sumValue };
};
