/* eslint-disable
    no-multi-assign,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
let SmkPT;
module.exports = (SmkPT = {});

const React = require('react');
const { PropTypes: PT } = require('prop-types');
const Granularity = require('utils/granularity');

const _group = PT.shape({
    name: PT.string,
    values: PT.arrayOf(PT.number)
});

SmkPT.dataGroups = PT.shape({
    nPoints: PT.number.isRequired,
    groups: PT.arrayOf(_group).isRequired,
    allGroups: PT.arrayOf(_group).isRequired,
    totalGroup: _group
});

SmkPT.dataGroupsSet = PT.objectOf(SmkPT.dataGroups);

SmkPT.dataGroupsOrSet = PT.oneOfType([SmkPT.dataGroups, SmkPT.dataGroupsSet]);

// groupSpec: { color?, ... }
SmkPT.groupSpec = PT.shape({
    color: PT.string });

// groupSpecs: { <groupName>: groupSpec }
SmkPT.groupSpecs = PT.objectOf(SmkPT.groupSpec);

SmkPT.granularity = PT.oneOf(Granularity.granularities);
