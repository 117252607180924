// @flow
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import Loadable from 'shared/components/Loadable';
import LoginStore from 'react/utils/login';
import Http from 'react/utils/http';
import Intercom from 'react/utils/intercom';
import preLoginRoutes from './PreLogin/routes';

const Login = Loadable({
    loader: () => import(/* webpackPrefetch: true */ './PreLogin/Login')
});

const Activation = Loadable({
    loader: () => import('./PreLogin/Activation')
});

const PasswordReset = Loadable({
    loader: () => import('./PreLogin/PasswordReset')
});

const LoggedIn = Loadable({
    loader: () => import(/* webpackPrefetch: true */ './LoggedIn')
});

type Props = {
  history: RouterHistory
};

export default class App extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        props.history.listen(this.onRouteChange);
        addStagingOrLocalPrefixToTitle();
        getAppVersion((version: string) => {
            console.info(`Current version (etag): ${version}`);
            this.appVersion = version;
        });
    }

  appVersion: string;

  onRouteChange = (location: { pathname: string }, action: string) => {
      if (action === 'PUSH') {
          Http.cancelAllRequests();
      }
      if (!LoginStore.isImpersonated()) {
          Intercom.track('Viewed Page', { path: location.pathname });
          Intercom.updateUrl();
      }
      getAppVersion((version: string) => {
          if (version !== this.appVersion) {
              window.location.reload();
          }
      });
  };

  render() {
      return (
          <ConnectedRouter history={this.props.history}>
              <Switch>
                  <Route
                      path={preLoginRoutes.activationRoute.path}
                      render={(router) => (
                          <Activation
                              router={router}
                              token={router.match.params.token}
                          />
                      )}
                  />
                  <Route
                      path={preLoginRoutes.passwordResetRoute.path}
                      render={(router) => (
                          <PasswordReset
                              router={router}
                              token={router.match.params.token}
                          />
                      )}
                  />
                  <Route
                      render={(router) => (
                          LoginStore.isLoggedIn() ? (
                              <LoggedIn router={router} />
                          ) : (
                              <Login router={router} />
                          )
                      )}
                  />
              </Switch>
          </ConnectedRouter>
      );
  }
}

function getAppVersion(callback: (version: string) => void) {
    // We use etag of the index.html file to identify the current app version.
    // Our backend express server generates weak etag (using CRC32) on static assets, which is fairly performant.
    const INDEX_HTML_PATH = '/index.html';
    Http.head(INDEX_HTML_PATH, {}, {}, (result, errmsg, resp) => {
        callback(resp.headers.etag);
    });
}

function addStagingOrLocalPrefixToTitle() {
    let prefix = '';
    if (isStaging()) {
        prefix = '(STAGING)';
    } else if (isLocal()) {
        prefix = '(LOCAL)';
    } else {
        return;
    }
    if (!document.title.startsWith(prefix)) {
        document.title = `${prefix} ${document.title}`;
    }
}

function isLocal() {
    const subDomain = getSubdomain();
    return (
        subDomain.includes('localhost')
      || subDomain === '192'
      || subDomain === '172'
    );
}

function isStaging() {
    return getSubdomain().includes('staging');
}

function getSubdomain() {
    const hostnameParts = window.location.hostname.split('.');
    return hostnameParts[0].toLowerCase();
}
