/* eslint-disable
    consistent-return,
    func-names,
    no-multi-assign,
    no-param-reassign,
    no-return-assign,
    no-var,
    prefer-rest-params,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Intercom;
module.exports = (Intercom = {});

// This file contains all methods used for Intercom
// Official documentation here: https://developers.intercom.com/docs/intercom-javascript
//
// Intercom('boot', intercomSettings)
//   If you'd like to control when Intercom is loaded, you can use the 'boot' method.
//   Called every time a new user is logged in (including Super Admin 'Log in as User')
//
// Intercom('update', intercomSettings)
//   Calling the update method with a JSON object of user details will update Intercom with those fields
//   in addition to logging an impression at the current URL and looking for new messages for the user.
//   Called every time url changes
//
// Intercom('shutdown')
//   call the Intercom shutdown method to clear your users’ conversations anytime they logout of your
//   application. Otherwise, the cookie we use to track who was most recently logged in on a given device
//   or computer will keep these conversations in the Messenger for one week. This method will effectively
//   clear out any user data that you have been passing through the JS API.

Intercom.running = false; // Keep intercom status here

// Intercom script to load Intercom into window
const _initializeIntercom = function (user) {
    if (typeof (window.Intercom) === 'function') {
        window.Intercom('reattach_activator');
        return window.Intercom('update', _createIntercomSettings(user));
    }
    const d = document;
    const loadScript = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/u5b76lpk';
        const x = d.getElementsByTagName('script')[0];
        return x.parentNode.insertBefore(s, x);
    };

    loadScript();

    var i = function () { return i.c(arguments); };
    i.q = [];
    i.c = (args) => i.q.push(args);
    return window.Intercom = i;
};

// originally part of intercom script, window will always exist by the time we call _initializeIntercom
// instaed we invoke loadScript immediately
// if window.attachEvent
//     window.attachEvent 'onload', loadScript
// else
//     window.addEventListener 'load', loadScript, false

// user: { id, email, firstName, lastName, organization, garages? }
//   garages: []
Intercom.boot = function (user) {
    _initializeIntercom(user);
    Intercom.running = true;
    return window.Intercom('boot', _createIntercomSettings(user));
};

Intercom.updateUrl = function () {
    if (!Intercom.running) { return; }
    return window.Intercom('update', { url: window.location.href });
};

Intercom.shutdown = function () {
    if (!Intercom.running) { return; }
    window.Intercom('shutdown');
    return Intercom.running = false;
};

Intercom.showNewMessage = function (message) {
    if (!Intercom.running) { return; }
    return window.Intercom('showNewMessage', message);
};

// Events should be in the form of "Did Foo". No more than 120 event types are supported.
// See https://docs.intercom.com/the-intercom-platform/track-events-in-intercom for detail.
Intercom.track = function (event, metadata) {
    if (metadata == null) { metadata = {}; }
    if (!Intercom.running) { return; }
    return window.Intercom('trackEvent', event, metadata);
};

var _createIntercomSettings = function (user) {
    const { id, firstName, lastName, organization, email, garages } = user;

    return {
        app_id: 'u5b76lpk',
        user_id: id,
        name: `${firstName} ${lastName}`,
        organization,
        email,
        url: window.location.href,
        garages: (garages != null ? garages.length : undefined) || 0
    };
};
