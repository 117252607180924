import Http from 'react/utils/http';
import Misc from 'react/utils/misc';

const Tracker = {};

Tracker.log = (errorObj, user, pageUrl, request, response) => {
    if (!shouldLogError(pageUrl)) {
        console.warn(`skip logging ${errorObj}`);
        return;
    }
    const payload = Tracker._getPayload(errorObj, user, pageUrl, request, response);
    Http.post(Tracker._getLoggingUrl(pageUrl), payload, { dontShowError: true }, () => {
        console.log('Done posting error');
    });
};


Tracker._getLoggingUrl = (pageUrl) => {
    const idxSlashAfterHost = pageUrl.indexOf('/', 'https://'.length + 1);
    const host = pageUrl.substring(0, idxSlashAfterHost);
    return `${host}/api/tracker/v1/log/external-instrument/source_id/web`;
};

Tracker._getPayload = (errorObj, user, pageUrl, request = undefined, response = undefined) => {
    const ret = {
        severity: 'ERROR',
        description: {},
    };
    if (!errorObj) {
        ret.description.message = 'Null error object';
    } else {
        ret.description.message = errorObj.message;
        ret.description.exception = errorObj.stack;
        ret.description = _assignIf(ret.description, 'errorStatus', errorObj.status);
        ret.description = _assignIf(ret.description, 'errorUrl', errorObj.url);
    }
    ret.description = _assignIfJson(ret.description, 'requestJson', request);
    ret.description = _assignIfJson(ret.description, 'responseJson', response);

    ret.description.recentLogs = Misc.allLogs.slice(-300, -1);
    ret.description.url = pageUrl;
    ret.userEmail = user && user.email ? user.email : 'No logged-in user';
    const onCall = ret.userEmail.match(/smarking.net/) ? '' : '[ONCALL] ';
    ret.eventCode = `${onCall}WEB-ERROR`;
    return ret;
};


const _assignIf = (o, key, value) => {
    if (value) {
        return { [key]: value, ...o };
    }
    return o;
};
const _assignIfJson = (o, key, value) => {
    if (value) {
        return _assignIf(o, key, JSON.stringify(value));
    }
    return o;
};

const shouldLogError = (pageUrl) => (
    !pageUrl.match(/api\/tracker/)
);

export default Tracker;
