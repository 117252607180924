/* eslint-disable
    func-names,
    no-multi-assign,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let functions;
const _ = require('lodash');
const moment = require('moment');
const Time = require('./time');

// Get the past and future parameters for data query in the following format:
//
//     { past, future }
//       past/future: { start, hours }
//
// Depending on the `apiFromDate` and `hours`, calculate the `start` and `hours` parameters for
// past and future queries.
//
// For past, the start time is the hour of `apiFromDate`, and the number of hours is until the
// full hour of the current garage time.
//
// For future, the start time is the full hour of the current garage time (note that this time is
// in the past), and the end time is the overall end time derived from `apiFromDate` and `hours`.
//
// The reason that the start time of the future is set to the full hour of the current garage time
// is because we don't get the complete data for the current hour yet. So we need to use predicted
// data for the current hour. But this does not apply for occupancy numbers since we already know
// the occupancy at the past hour. Call `Time.getApiPastFutureQueryParamsForOccupancy()` to get the
// params for occupancy queries.
//
// Example: assume `apiFromDate` is 2016-09-01T10:30:00, `hours` is 5, and current garage time is
// 2016-09-01T12:45:00. We return:
//
//    past: { start: moment.utc('2016-09-01T10:00:00'), hours: 2 }
//    future: { start: moment.utc('2016-09-01T10:00:00'), hours: 3 }
//
// Params:
//   - apiFromDate: the overall "from" date
//   - hours: the total number of hours to query
//   - timezone: the timezone of the garage. used to calculate the current garage time.
//
// return { past?, future? }
//   past/future: { start: Date, hours }
//     (not set if they are not needed)
//
module.exports = (functions = {});

functions.getApiPastFutureQueryParams = function (apiFromDate, hours, timezone) {
    const now = Time.now(timezone);
    const startMoment = moment.utc(apiFromDate).startOf('hour');
    const endMoment = moment.utc(apiFromDate).add(hours, 'hour').startOf('hour');

    const times = {};
    const currentHour = moment.utc(now).startOf('hour');
    if (apiFromDate <= now) {
        const end = _.min([endMoment, currentHour]);
        times.past = {
            start: startMoment.toDate(),
            hours: end.diff(startMoment, 'hour')
        };
    }

    if (endMoment > now) {
        const start = _.max([startMoment, currentHour]);
        times.future = {
            start: start.toDate(),
            hours: endMoment.diff(start, 'hour')
        };
    }

    return times;
};

// Get the past and future parameters for occupancy data query in the following format:
//
//     { past?, future? }
//       past/future: { start: Date, hours }
//
// See document for `Time.getApiPastFutureQueryParams()`. The difference here is that we move one
// hour forward for the future start time if `times` has both past and future components.
//
// An example: assume `hours` == 4
//
//       |           |      .    |           |           |
//     start       hour 2  now  hour 3     hour 4      hour 5
//
// `times.future.start` was at hour 2. We add one hour to move it to hour 3, and subtract one hour
// from future hours so the end time is still hour 4. For the past data, we add one hour to move the
// end time of past to hour 2.
//
functions.getApiPastFutureQueryParamsForOccupancy = function (apiFromDate, hours, timezone) {
    const times = Time.getApiPastFutureQueryParams(apiFromDate, hours, timezone);
    const { past, future } = times;
    if ((future != null) && (past != null)) {
        past.hours += 1;
        future.start = moment(future.start).add(1, 'hour').toDate();
        future.hours -= 1;
        if (times.future.hours === 0) { delete times.future; }
    }
    return times;
};
