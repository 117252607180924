/* eslint-disable
    class-methods-use-this,
    max-len,
    no-multi-assign,
    no-shadow,
    no-unused-vars,
    operator-linebreak,
    prefer-const,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let DateRange;
const _ = require('lodash');
const moment = require('moment');
const Time = require('react/utils/time');
const Assert = require('react/utils/assert');
const TimeRangeOptions = require('react/utils/time_range_options');

//= =================
// One of the following combinations must be present to infer a date range:
//
// 1. api{From|To}Date OR
// 2. calendar{From|To}Date & bizStartHour OR
// 3. timeRange & timezone & bizStartHour OR
// 4. defaultTimeRange & timezone & bizStartHour
//
//= =================
// opts:
//
// - {calendar|api}{From|To}Date
// - timeRange
// - defaultTimeRange: If can't get a DateRange from above dates, then will use
//   this to determine range.
//
// - api{Min|Max}Date
//
// - bizStartHour
//
//= =================
// Usage:
//
// dr = DateRange(opts)
//
// dr object will have all of following attributes:
//
// - {calendar|api}{From|To}Date
// - timeRange
//
// If in api{Min|Max}Date is given in opts, then dr will also have this:
//
// - calendar{Min|Max}Date
//
// All the dates are only meaningful when they're converted to UTC, and removed
// timezone!
//
module.exports = (DateRange = class DateRange {
    // 1) Get api dates
    //
    // - if apiFromDate and apiToDate exist
    //   use api dates directly
    //
    // - else if calendar dates exist
    //   convert them to api dates
    //
    // - else
    //   if timeRange doesn't exist
    //      set timeRange to default
    //   convert timeRange to api dates
    //
    // 2) Limit api dates by min / max api dates, if any.
    //
    // 3) Generate results
    //
    constructor(opts) {
        let calendarMaxDate; let
            calendarMinDate;
        let {
            // TODO: TB change to calendarToDay and calendarFromDay
            // Days that come in with no regard for time
            calendarFromDate, calendarToDate,
            apiFromDate, apiToDate,
            apiMinDate, apiMaxDate,
            timeRange, defaultTimeRange, bizStartHour, timezone
        } = opts;

        const origApiFromDate = apiFromDate;

        // 1) Get api dates
        if (apiFromDate && apiToDate) {
            // do nothing
        } else {
            this._verifySpecs(bizStartHour != null);

            if (calendarFromDate && calendarToDate) {
                apiFromDate = Time.bizStartTimeOfDay(calendarFromDate, bizStartHour).toDate();
                apiToDate = Time.calendarToDateToApiToDate(calendarToDate, bizStartHour);
            } else {
                if (!timeRange) { timeRange = defaultTimeRange; }
                this._verifySpecs(((timeRange != null) && (timezone != null)));

                ({ fromDate: apiFromDate, toDate: apiToDate } = Time.getTimeRangeFromToDates(timeRange, { timezone, bizStartHour }));
            }
        }

        // 2) Limit api dates by min / max api dates, if any.
        if (apiMinDate && apiMaxDate) {
            apiToDate = _.min([apiToDate, apiMaxDate]);
            // In case apiToDate < apiMinDate, we want to set apiToDate to 1 day later to have a 24 hour time range.
            apiToDate = _.max([apiToDate, moment(apiMinDate).add(1, 'day').toDate()]);
            apiFromDate = _.min([apiFromDate, apiToDate]);

            // Include whole day, if fromDate has been modified
            if (!moment(apiFromDate).isSame(origApiFromDate)) {
                apiFromDate = Time.bizDayStart(apiFromDate, bizStartHour);
            }
            apiFromDate = _.max([apiFromDate, apiMinDate]);
        }

        // 3) Generate results
        calendarFromDate = Time.bizDateToCalendarDate(apiFromDate, bizStartHour);
        calendarToDate = Time.apiToDateToCalendarToDate(apiToDate, bizStartHour);

        if (apiMinDate && apiMaxDate) {
            calendarMinDate = Time.bizDateToCalendarDate(apiMinDate, bizStartHour);
            calendarMaxDate = Time.apiToDateToCalendarToDate(apiMaxDate, bizStartHour);
        }

        _.extend(this, {
            apiFromDate,
            apiToDate,
            calendarFromDate,
            calendarToDate,
            calendarMinDate,
            calendarMaxDate,
            timeRange,
            timezone,
            bizStartHour,
            apiMinDate,
            apiMaxDate
        });
    }

    formatCalendarDates() {
        return `${Time.namedFormat(this.calendarFromDate, 'url.date')} - ${Time.namedFormat(this.calendarToDate, 'url.date')}`;
    }

    getFormattedText() {
        const { calendarFromDate, calendarToDate } = this;
        const strFromDate = Time.namedFormat(calendarFromDate, 'human.dayShort');
        const strToDate = Time.namedFormat(calendarToDate, 'human.dayShort');
        if (strFromDate === strToDate) {
            return strFromDate;
        }
        return `${strFromDate}-${strToDate}`;
    }

    // matchType: weekday | day
    //
    // If matchType is weekday, will convert apiFromDate and apiToDate using
    // sameWeekdayLastYear, otherwise uses sameDateLastYear.
    //
    // Note this function only creates a new DateRange with apiFromDate and
    // apiToDate. Other attributes, such as timezone and bizStartHour, are
    // ignored.
    sameDateRangeLastYear(matchType) {
        Assert.includes(['weekday', 'day'], matchType);

        if (matchType === 'weekday') {
            return new DateRange({
                apiFromDate: Time.sameWeekdayLastYear(this.apiFromDate),
                apiToDate: Time.sameWeekdayLastYear(this.apiToDate)
            });
        }
        return new DateRange({
            apiFromDate: Time.sameDayLastYear(this.apiFromDate),
            apiToDate: Time.sameDayLastYear(this.apiToDate)
        });
    }

    sameDateRangeFromYear(matchType, year) {
        Assert.includes(['weekday', 'day'], matchType);
        const diff = this.getApiHours();
        if (matchType === 'weekday') {
            const from = Time.sameWeekdayFromYear(this.apiFromDate, year);
            const to = moment(from).clone().add(diff, 'hours').toDate();
            return new DateRange({ apiFromDate: from, apiToDate: to });
        }
        const from = Time.sameDayFromYear(this.apiFromDate, year);
        const to = moment(from).clone().add(diff, 'hours').toDate();
        return new DateRange({ apiFromDate: to, apiToDate: from });
    }

    // Compute difference between apiToDate and apiFromDate hours
    getApiHours() {
        return moment(this.apiToDate).diff(this.apiFromDate, 'hours');
    }


    _verifySpecs(conditions) {
        if (!conditions) {
            throw Error(`\
Invalid date range spec! At least one of the
required value pair or value needs to be present.
See comment in 'date_range' file.\
`);
        }
    }

    // Convenience function to group a large trunk of code. Takes in profile and default timeRanges,
    // outputs date range and time range options.
    //
    // opts
    // - profile, timeRangeOptions (Required)
    // - timeRange, fromDateStr, toDateStr (Optional)
    //
    // result: { dateRange, timeRangeOptions }
    static getDateRangeAndOptions(opts) {
        Assert.exists(opts.timeRangeOptions, 'timeRangeOptions does not exist!');
        const { timezone, bizStartHour, apiMinDate, apiMaxDate, timeRangeOptions: intendedTimeRangeOptions, timeRange: inputTimeRange, fromDateStr, toDateStr } = opts;
        // It's possible there's no available timeRangeOptions (due to lack of data).
        const timeRangeOptions = Time.getValidTimeRanges(intendedTimeRangeOptions, apiMinDate, apiMaxDate, { timezone, bizStartHour });
        const defaultTimeRange =
            // Make the default time range 'fourteenDayForecast' if it is valid
            _.find(timeRangeOptions, { value: 'fourteenDayForecast', disabled: false })
                ? 'fourteenDayForecast'
                : (timeRangeOptions[0] || intendedTimeRangeOptions[0]).value;
        const timeRange = TimeRangeOptions.getValidTimeRangeOrUndefined(timeRangeOptions, inputTimeRange);

        const calendarFromDate = fromDateStr ? Time.parseFromUrlDateStr(fromDateStr) : undefined;
        const calendarToDate = toDateStr ? Time.parseFromUrlDateStr(toDateStr) : undefined;

        const dateRange = new DateRange({
            timeRange,
            defaultTimeRange,
            calendarFromDate,
            calendarToDate,
            timezone,
            bizStartHour,
            apiMinDate,
            apiMaxDate
        });

        return { dateRange, timeRangeOptions };
    }
});
