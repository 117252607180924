// @flow
import './NotFound404.styl';

import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Button } from 'semantic-ui-react';

import imgParkingLotBackground from './parking_lot_background.png';
import imgFigureWithMonoscope from './figure_with_monoscope.png';

const NotFound404 = () => (
    <div className="not-found-404">
        <img
            alt="Parking lot"
            className="parking-lot-background"
            src={imgParkingLotBackground}
        />
        <div className="page-bg-container">
            <div className="page-figure-container">
                <img
                    alt="Cartoon man on ladder looking around with telescope"
                    src={imgFigureWithMonoscope}
                />
            </div>
            <div className="page-content-container">
                <Header as="h1" content="Oops! 404 page not found." />
                <Header as="h2" content="Got lost in your parking lot?" />
                <Link to="/">
                    <Button content="Take me home" />
                </Link>
            </div>
        </div>
    </div>
);

export default NotFound404;
