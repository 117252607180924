// @flow
import './LoadingSpinner.styl';

import React from 'react';
import _ from 'lodash';

type Props = {
  style?: 'rectangle' | 'spinner',
  size?: 'small' | 'medium' | 'large'
};

const LoadingSpinner = (props: Props) => (
    <div className="smk-loading-spinner" data-style={props.style} data-size={props.size}>
        {
            props.style === 'rectangle' ? (
                _.times(5, (idx) => (
                    <div key={idx} className={`rect rect${idx}`} />
                ))
            ) : (
                <i className="fa fa-spin fa-spinner" />
            )
        }
    </div>
);

LoadingSpinner.defaultProps = {
    style: 'rectangle',
    size: 'large'
};

export default LoadingSpinner;
