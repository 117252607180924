/* eslint-disable
    consistent-return,
    func-names,
    max-len,
    no-loop-func,
    no-multi-assign,
    no-param-reassign,
    no-unused-vars,
    no-var,
    radix,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Assert;
module.exports = (Assert = {});
const _ = require('lodash');
const Misc = require('utils/misc');

Assert.true = function (val, message) {
    if (val !== true) {
        throw Error(message != null ? message : 'Value must be true!');
    }
};

Assert.includes = function (collection, value, valueName) {
    if (valueName == null) { valueName = 'value'; }
    if (!_.includes(collection, value)) {
        throw Error(`Invalid ${valueName}: ${value}. Must be one of ${collection}.`);
    }
};


Assert.eachIsOneOf = (collection, validValues) => Array.from(collection).map((val) => Assert.includes(validValues, val));


Assert.validGarageId = function (garageId) {
    if (_.isNaN(parseInt(garageId))) {
        throw Error(`Invalid garageId: ${garageId}. Must be an integer.`);
    }
};

Assert.isEqual = function (obj1, obj2) {
    if (!_.isEqual(obj1, obj2)) {
        throw Error(`Not equal: ${obj1}, ${obj2}`);
    }
};

Assert.exists = function (obj, message) {
    if (message == null) { message = undefined; }
    if ((obj == null)) {
        throw Error(message != null ? message : 'Not exists!');
    }
};

// Same as Misc.hasAll, but throws an error if result is false
Assert.hasAll = function (obj, paths) {
    if (!Misc.hasAll(obj, paths)) {
        const missingPaths = _.filter(paths, (path) => !_.has(obj, path));

        throw Error(`Assert.hasAll: obj is required to have paths (${paths.join(', ')}), but missing (${missingPaths.join(', ')})`);
    }
};

Assert.hasNestedProp = function (obj, paths) {
    for (let idx = 0; idx < paths.length; idx++) {
        const path = paths[idx];
        var allPathsToThisPoint = _.slice(paths, 0, idx + 1);
        const val = _.get(obj, allPathsToThisPoint);
        if ((val == null)) {
            return (() => { throw Error(`Assert.hasNestedProp: obj is require to have deeply nested property ${paths.join('.')} but failed at ${allPathsToThisPoint.join('.')}`); })();
        }
    }
};

// Example: Assert.type {}, _.isObject
Assert.type = function (variable, typeCheckFunction, message) {
    if (message == null) { message = undefined; }
    if (!typeCheckFunction(variable)) {
        message = message || `Type of variable (${variable}) is invalid.`;
        throw Error(message);
    }
};
