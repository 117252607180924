/* eslint-disable
    func-names,
    no-return-assign,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Return actual localStorage if possible (such as in browser)
// Else, return a JS object with same function signatures, and can actually
// store stuff (but only in memory).

let localStorage;
const isLocalStorageAvailable = function () {
    try {
        // Try to set a test key to verify that local storage works.
        // Note that it works as intended if `window` or `window.localStorage` is undefined or unaccessible.
        const testKey = '__smk_test';
        window.localStorage.setItem(testKey, 'test');
        window.localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

module.exports = isLocalStorageAvailable()
    ? window.localStorage
    : (localStorage = {
        setItem(key, value) {
            return this[key] = value;
        },

        getItem(key) {
            return this[key];
        },

        removeItem(key) {
            return this[key] = undefined;
        }
    });
