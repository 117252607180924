/* eslint-disable
    consistent-return,
    func-names,
    no-console,
    no-multi-assign,
    no-param-reassign,
    no-undef,
    no-var,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Track;
module.exports = (Track = {});
// Provides a wrapper around mixpanel to stabalize API.
const _ = require('lodash');
const URI = require('urijs');
const Login = require('react/utils/login');
const Assert = require('react/utils/assert');

Track.attemptedLogin = (email) => _trackEventForExternalEmail('Attempted login', email, { email });

Track.loggedIn = (email) => _trackEventForExternalEmail('Logged in', email, { email });

var _trackEventForExternalEmail = function (event, email, data) {
    if (data == null) { data = {}; }
    if (email.match(/@smarking/i)) { return; }
    return _trackEvent(event, data);
};

Track.pageView = function (pageTitle) {
    const parsedSearch = URI.parseQuery(window.location.search);
    const properties = _.extend({ page: pageTitle, pathname: window.location.pathname }, parsedSearch);
    return Track.activity('Page View', properties);
};

Track.activity = function (action, data) {
    if (data == null) { data = {}; }
    if (!Login.loggedInUser) {
        console.warn(`Login.loggedInUser is undefined! Skip tracking action: ${action}`);
        return;
    }
    return _trackEventForExternalUser(action, Login.loggedInUser, data);
};

var _trackEventForExternalUser = function (event, user, data) {
    if (data == null) { data = {}; }
    if (Login.isImpersonated() || user.superAdmin) { return; }
    Assert.type(data, _.isPlainObject);
    const userData = _.pick(user, ['organization', 'firstName', 'lastName', 'email', 'role']);
    userData.garageCount = user.garages.length;
    const trackedData = _.extend({}, data, userData);

    return _trackEvent(event, trackedData);
};

var _trackEvent = function (event, data) {
    if (data == null) { data = {}; }
    return mixpanel.track(event, data);
};

Track.identify = (...args) => mixpanel.identify(...Array.from(args || []));

Track.setPeople = (user) => mixpanel.people.set({
    $email: user.email,
    $first_name: user.firstName,
    $last_name: user.lastName,
    organization: user.organization
});
