/* eslint-disable
    func-names,
    implicit-arrow-linebreak,
    max-len,
    no-multi-assign,
    no-nested-ternary,
    no-plusplus,
    no-var,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Granularity;
module.exports = (Granularity = {});

const _ = require('lodash');
const moment = require('moment');
const Assert = require('utils/assert');
const Time = require('utils/time/time');

Granularity.specs = {
    Hourly: { count: 1, unit: 'hour', amchartsMinPeriod: 'hh' },
    Daily: { count: 1, unit: 'day', amchartsMinPeriod: 'DD' },
    Weekly: { count: 1, unit: 'week', amchartsMinPeriod: '7DD' },
    Monthly: { count: 1, unit: 'month', amchartsMinPeriod: 'MM' },
    Quarterly: { count: 1, unit: 'quarter', amchartsMinPeriod: '3MM' },
    Yearly: { count: 1, unit: 'year', amchartsMinPeriod: 'YYYY' },
    Subtotal: { count: 1, unit: 'subtotal', amchartsMinPeriod: 'subtotal' },
};

Granularity.granularities = _.keys(Granularity.specs);

Granularity.getTimeSpan = function (granularity) {
    Assert.includes(this.granularities, granularity);
    return Granularity.specs[granularity];
};

// Group by the given granularity.
//
// granularity: oneOf(@granularities)
// startDate: The starting date of the given hourly data
// hourlyData: [ hourlyVal ]
// aggFunc: ( [ val ] ) -> aggVal
//
// result: { startDate, data: [ aggVal ] }
//
Granularity.groupBy = function (granularity, startDate, hourlyData, aggFunc, bizStartHour) {

    if (granularity === 'Subtotal') {
        return {
            startDate,
            data: [aggFunc(hourlyData)]
        };
    }
    const { count } = Granularity.getTimeSpan(granularity);
    const unit = _getUnitForMomentStartOf(granularity);

    if (count !== 1) {
        throw Error('Can only group by 1 hour, 1 day, 1 week, or 1 month for now.');
    }

    if (unit === 'hour') {
        return {
            startDate,
            data: _.map(hourlyData, (d) => aggFunc([d]))
        };
    }
    // Turn hourly data array to an array with index and value for grouping.
    //   For example, [100, 30, ...] => [{ index: 0, value: 100 }, { index: 1, value: 30 }, ...]
    const indexValueArray = _.zipWith(__range__(0, hourlyData.length, false), hourlyData,
        (index, value) => ({
            index,
            value
        }));
    const indexToGroup = _indexToGranularityGroup(unit, startDate, bizStartHour);

    const groups = _.groupBy(indexValueArray, indexToGroup);
    const groupArray = _.map(groups, (values, key) => ({
        time: key,
        value: aggFunc(_.map(values, 'value'))
    }));
    const sortedArray = _.sortBy(groupArray, 'time');
    const firstDate = _.first(sortedArray);

    return {
        startDate: (firstDate) ? moment.utc(firstDate.time).toDate() : startDate,
        data: _.map(sortedArray, 'value')
    };
};
Granularity.getSeconds = function (granularity) {
    const { count, unit } = this.getTimeSpan(granularity);
    if (unit == 'subtotal') {
        return moment.duration(count, 'month').asSeconds();
    }
    return moment.duration(count, unit).asSeconds();
};

// See if <granularity> is coarser (longer time span) than or equal to
// <compared> granularity.
// result: Bool
Granularity.largerOrEqualTo = (granularity, comparedGranularity) => Granularity.getSeconds(granularity) >= Granularity.getSeconds(comparedGranularity);

// Return a function that maps an index to a date string based on timespan unit.
//
// For example, for start time '2016-01-01T23:00' and timspanUnit 'day', we
// return a function that maps:
//
//     Index = 0 (corresponds to '2016-01-01T23:00') => '2016-01-01'
//     Index = 1 (corresponds to '2016-01-02T00:00') => '2016-01-02'
//     Index = 2 (corresponds to '2016-01-02T01:00') => '2016-01-02'
//     ...
//
// If `bizStartHour` is not 0, we adjust the business starting day accordingly.
// For example, if bizStartHour is 5, we have
//
//     ...
//     Index = 5 (corresponds to '2016-01-02T04:00') => '2016-01-01'
//     Index = 6 (corresponds to '2016-01-02T05:00') => '2016-01-02'
//     ...
//
// For weekly timspanUnit, we assume a week starts on Monday.
//
var _indexToGranularityGroup = (timspanUnit, startDate, bizStartHour) => (value) => // LATER TP (2017-03-23 19:15): Use getStartOfGranularity potentially
// (if performance is not an issue)
    moment.utc(startDate).add(value.index - bizStartHour, 'hours').startOf(timspanUnit).format('YYYY-MM-DD');


// Gets the starting date of a granularity, given any date in the middle.
//
// result: Date
//
// Example:
//   Given  2016-03-01 03:04:05, Monthly and bizStartHour = 5
//   return 2016-02-01 00:00:00
// TODO TB use this function (currently not in use)
Granularity.startOfGranularity = function (date, granularity, bizStartHour) {
    const unit = _getUnitForMomentStartOf(granularity);

    if (unit === 'subtotal') {
        return 'subtotal';
    }

    if (unit === 'hour') {
        return moment(date).startOf('hour').toDate();
    }
    return moment.utc(Time.bizDayStart(date, bizStartHour)).startOf(unit).toDate();
};

var _getUnitForMomentStartOf = function (granularity) {
    const { unit } = Granularity.getTimeSpan(granularity);
    // To be consistent across the system (e.g. the digest service), we use Monday as the start of the week.
    // 'isoweek' starts on Monday while 'week' starts Sunday, so use 'isoweek' here.
    if (unit === 'week') { return 'isoweek'; } return unit;
};

function __range__(left, right, inclusive) {
    const range = [];
    const ascending = left < right;
    const end = !inclusive ? right : ascending ? right + 1 : right - 1;
    for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
        range.push(i);
    }
    return range;
}
