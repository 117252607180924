/* eslint-disable
    consistent-return,
    func-names,
    max-len,
    no-console,
    no-multi-assign,
    no-var,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Slack;
module.exports = (Slack = {});

const _ = require('lodash');
const Http = require('react/utils/http');
const Misc = require('react/utils/misc');
const Login = require('react/utils/login');

const IGNORED_ERRORS = [
    "'Symbol' is undefined",
    "Can't find variable: DD",
    "Object doesn't support property or method 'jscomp_symbol_iterator0'",
    'Error when calling POST /api/users/v1/login: UNAUTHORIZED, Bad login and password combination',
    "Object doesn't support property or method 'Symbol(Symbol.iterator)'",
    "Cannot read property 'querySelectorAll' of null",
    'Possible causes: the network is offline, Origin is not allowed by Access-Control-Allow-Origin, the page is being unloaded, etc.'
];
Slack._postedIgnoredErrors = {};

Slack._internalInstrumentUrl = process.env.SLACK_WEBHOOK_FOR_INTERNAL_TUNNING_CHANNEL;
Slack._externalInstrumentUrl = process.env.SLACK_WEBHOOK_FOR_INSTRUMENTATION_CHANNEL;


Slack.postErrorToInstrumentChannel = function (errorObj, user, pageUrl) {
    let url;
    if (pageUrl.match(/staging.smarking/)) {
        console.warn('Skip posting for staging.');
        return;
    }

    if (errorObj === null) {
        console.warn('Skip posting: errorObj is null');
        return;
    }

    if (shouldErrorBeIgnored(errorObj.message)) {
        if (Slack._postedIgnoredErrors[errorObj.message] != null) {
            return;
        }

        Slack._postedIgnoredErrors[errorObj.message] = true;
        url = Slack._internalInstrumentUrl;
    }

    if (url == null) {
        url = (user != null ? user.superAdmin : undefined) || Login.isImpersonated()
            ? Slack._internalInstrumentUrl
            : Slack._externalInstrumentUrl;
    }

    const payload = getPayload(errorObj, pageUrl, user);
    return Slack._postPayloadToIncomingWebhook(url, payload);
};

var shouldErrorBeIgnored = (errorMsg) => _.some(IGNORED_ERRORS, (ignored) => _.includes(errorMsg, ignored));


const getLoggedInUserPayload = (errorMsg, pageUrl, user) => ({
    pretext: `Error *${errorMsg}* when user *${user.email}* is viewing page ${pageUrl}`,
    author_name: `${user.firstName} ${user.lastName}`,

    fields: [
        { title: 'User', value: getInfoStr(user), short: true },
        { title: 'Message ID', value: _.uniqueId(), short: true }
    ]
});

var getInfoStr = (user) => `\
Name: ${user.firstName} ${user.lastName},
Email: ${user.email || 'No email'},
Garages: ${(user.garages || []).length},
Organization: ${user.organization || 'No organization'}\
`;

const getNonLoggedInUserPayload = (errorMsg, pageUrl) => ({
    pretext: `Error *${errorMsg}* when user is not logged in while viewing page ${pageUrl}`,
    author_name: 'No logged-in user',

    fields: [
        { title: 'User', value: 'No logged-in user', short: true },
        { title: 'Message ID', value: _.uniqueId(), short: true }
    ]
});

var getPayload = function (errorObj, pageUrl, user) {
    const recentLogs = Misc.allLogs.slice(-300);

    const userSpecificPayload = (user != null)
        ? getLoggedInUserPayload(errorObj.message, pageUrl, user)
        : getNonLoggedInUserPayload(errorObj.message, pageUrl);

    const commonPayload = {
        text: `\
*Error Stack*: \n ${errorObj.stack}\n\n
*Session logs*:\n ${recentLogs.join('\n')}\n\n\
`,
        mrkdwn_in: ['text', 'pretext'],
        footer: 'Smarking Frontend Instrumentation',
        ts: _.floor(new Date().getTime() / 1000)
    };

    return { attachments: [_.extend({}, commonPayload, userSpecificPayload)] };
};


// Lowest level function to post msg to slack using incoming webhook.
Slack._postPayloadToIncomingWebhook = function (url, payload) {
    if ((url == null) || !url.match(/hooks.slack.com/)) {
        return;
    }

    // TP: Somehow, for slack incoming webhook API to work, need to stringify the json payload. See
    // http://stackoverflow.com/questions/37409341/cant-send-an-incoming-slack-hook-message-through-angular1s-http
    return Http.post(url, JSON.stringify(payload), { headers: {} }, () => {});
};
