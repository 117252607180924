/* eslint-disable
    func-names,
    implicit-arrow-linebreak,
    max-len,
    no-cond-assign,
    no-multi-assign,
    no-nested-ternary,
    no-param-reassign,
    no-return-assign,
    no-shadow,
    no-unused-vars,
    radix,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// IMPORTANT NOTE: All the dates are only meaningful when converted to UTC
// timezone!
//
// Also, we separate 3 different time/date concepts:
//
// - Date: A javascript Date object with both Day (YYYY-MM-DD) and Time
//   (hh:mm:ss) information meaningful.
// - Day: A javascript Date object with only day part (YYYY-MM-DD) carrying
//   information
// - Time: A javascript Date object with only time part (hh:mm:ss) carrying
//   information
//
// Use one of the 3 as suffix of your variables to make it clear what
// information is meaningful.
//
// LATER TP Current code base mixes up these concepts in lots of places, we will
// clear them up over time.

let Time;
module.exports = (Time = {});

const moment = require('moment-timezone');
const _ = require('lodash');
const Assert = require('react/utils/assert');
const Granularity = require('react/utils/granularity');


Time.formats = {
    iso: 'YYYY-MM-DDTHH:mm:ss',
    human: {
        iso: 'YYYY-MM-DDTHH:mm:ss',
        hour: 'MMM D, YYYY (ddd), ha',
        day: 'MMM D (ddd), YYYY',
        week: '[Week of] MMM D, YYYY',
        month: 'MMMM, YYYY',
        quarter: 'YYYY [Q]Q',
        year: 'YYYY',
        dayShort: 'MM/DD/YYYY',
        description: 'h:mm A, MMM. D',
        subtotal: '[Subtotal] YYYY',
    },
    url: {
        // TODO TP Change to day
        date: 'YYYY-MM-DD',
        time: 'HHmm'
    }
};

// return: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct',
//   'Nov', 'Dec']
Time.getMonths = () => moment.monthsShort();

// Format name is anything that can be used in _.get method. It's used to pull
// actual format string from Time.formats.
//
// If timezone is specified, will convert to that timezone before formatting.
// By default uses UTC time.
//
// Example:
//
//     Time.namedFormat(new Date, 'human.iso', 'America/New_York')
//     -> "1986-12-03T23:03:20"
//
Time.namedFormat = function (dateOrMoment, formatName, timezone) {
    if (timezone == null) { timezone = 'UTC'; }
    const format = _.get(this.formats, formatName);
    if (!format) { throw Error(`No formatName called ${formatName}!`); }

    if (dateOrMoment) {
        return moment(dateOrMoment).tz(timezone).format(format);
    }
    return undefined;
};

// Reverse of Time.namedFormat
//
// If timezone is specified, will use that to set timezone for result.
//
// Example:
//
//     Time.namedFormat("1986-12-03T23:03:20", 'human.iso', 'US/east')
//
Time.parseNamedFormat = function (formattedString, formatName, timezone) {
    if (timezone == null) { timezone = 'UTC'; }
    const format = _.get(this.formats, formatName);
    if (!format) { throw Error(`No formatName called ${formatName}!`); }

    if (formattedString) {
        return moment.tz(formattedString, format, timezone).toDate();
    }
    return undefined;
};


// Convenience method since it's used a lot.
Time.formatAsUrlDateStr = (date) => Time.namedFormat(date, 'url.date', 'UTC');
Time.parseFromUrlDateStr = (dateStr) => Time.parseNamedFormat(dateStr, 'url.date', 'UTC');

// Convert a UTC time into local time.
//
// @param   {Date}    utcTime   Can also be a moment, or an ISO string
// @param   {String}  timezone  Time zone
//
// @return  {moment}            Moment in terms of local time
//
Time.utcToLocalTime = (utcTime, timezone) => moment.utc(utcTime).tz(timezone);

// Removes timezone offset compared to UTC. The result will be a different date.
//
// Examples (results are in -700 timezone because we assume browser tz is -700):
//
// Date: 2016/06/22 17:00 -700 -> Date: 2016/06/23 00:00 -700
// Date: 2016/06/22 14:00 -300 -> Date: 2016/06/22 17:00 -700
//
Time.removeTimezoneOffset = function (date) {
    const offsetMins = date.getTimezoneOffset();
    const newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() + offsetMins);
    return newDate;
};


// Get the number of points between two dates.
//
// granularity: One of Granularity.granularities
//
Time.pointsBetween = function (fromDate, toDate, granularity) {
    const fromMoment = moment.utc(fromDate);
    const toMoment = moment.utc(toDate);
    Assert.true(fromMoment.isBefore(toMoment), 'toDate must be larger than fromDate');
    const { unit, count } = Granularity.getTimeSpan(granularity);
    return toMoment.diff(fromMoment, unit) / count;
};

// Convert from and to times into human-friendly texts.
//
// @param   {Date}    fromTime  "From" time. Can also be moment, or ISO time string
// @param   {Date}    toTime    "To" time. Can also be moment, or ISO time string
//
// @return  {String}            Human-friendly from-to texts
//
Time.fromToTimesToText = function (fromTime, toTime) {
    const from = moment.utc(fromTime);
    const to = moment.utc(toTime).subtract(1, 'second');

    if (to.diff(from, 'days') < 1) {
        return `${from.format(this.formats.human.day)}`;
    }

    let fromFormat = 'MMM D (ddd)';
    if (from.year() !== to.year()) {
        fromFormat += ', YYYY';
    }
    const toFormat = 'MMM D (ddd), YYYY';
    const fromString = from.format(fromFormat);
    const toString = to.format(toFormat);
    return `${fromString} - ${toString}`;
};


Time.getFirstMidnightAtOrAfter = (date) => moment.utc(date).subtract(1, 'second').add(1, 'day').startOf('day')
    .toDate();


Time.now = function (timezone) {
    const now = timezone ? moment().tz(timezone) : moment();
    return moment.utc(now.format(Time.formats.human.iso));
};

Time.currentHour = function (timezone) {
    const hour = timezone ? moment().tz(timezone).startOf('hour') : moment().startOf('hour');
    return moment.utc(hour.format(Time.formats.human.iso));
};

Time.futureTimeFromNow = function (timezone, value, unit) {
    const future = timezone ? moment().tz(timezone).add(value, unit) : moment().add(value, unit);
    return future.format(Time.formats.human.description);
};

const _dayStart = (timezone, bizStartHour) => moment.utc(Time.bizDayStart(Time.now(timezone), bizStartHour));

const _weekStart = (timezone, bizStartHour) => _dayStart(timezone, bizStartHour).day('Monday');

const _monthStart = (timezone, bizStartHour) => _dayStart(timezone, bizStartHour).date(1);

const _yearStart = (timezone, bizStartHour) => _monthStart(timezone, bizStartHour).month(0);

const _formatFromTo = (fromMoment, toMoment) => ({
    fromDate: fromMoment.toDate(),
    toDate: toMoment.toDate()
});

Time.lastYear = function (timezone, bizStartHour) {
    const ys = _yearStart(timezone, bizStartHour);
    const f = moment.utc(ys).subtract(1, 'year');
    const t = moment.utc(ys);
    return _formatFromTo(f, t);
};

Time.pastYear = function (timezone, bizStartHour) {
    const ms = _monthStart(timezone, bizStartHour);
    const f = moment.utc(ms).subtract(1, 'year');
    const t = moment.utc(ms);
    return _formatFromTo(f, t);
};

Time.yearToDate = function (timezone, bizStartHour) {
    const f = _yearStart(timezone, bizStartHour);
    const t = Time.now(timezone);
    return _formatFromTo(f, t);
};

Time.last3Months = function (timezone, bizStartHour) {
    const t = _monthStart(timezone, bizStartHour);
    const f = moment.utc(t).subtract(3, 'month');
    return _formatFromTo(f, t);
};

Time.lastMonth = function (timezone, bizStartHour) {
    const t = _monthStart(timezone, bizStartHour);
    const f = moment.utc(t).subtract(1, 'month');
    return _formatFromTo(f, t);
};

Time.last30Days = function (timezone, bizStartHour) {
    const t = _dayStart(timezone, bizStartHour);
    const f = moment.utc(t).subtract(1, 'month');
    return _formatFromTo(f, t);
};

Time.monthToDate = function (timezone, bizStartHour) {
    const f = _monthStart(timezone, bizStartHour);
    const t = Time.now(timezone);
    return _formatFromTo(f, t);
};

Time.fourteenDayForecast = function (timezone, bizStartHour) {
    const today = _dayStart(timezone, bizStartHour);
    const f = moment.utc(today).subtract(1, 'week');
    const t = moment.utc(today).add(2, 'week');
    return _formatFromTo(f, t);
};

Time.lastWeek = function (timezone, bizStartHour) {
    const t = _weekStart(timezone, bizStartHour);
    const f = moment.utc(t).subtract(1, 'week');
    return _formatFromTo(f, t);
};

Time.weekToDate = function (timezone, bizStartHour) {
    const f = _weekStart(timezone, bizStartHour);
    const t = Time.now(timezone);
    return _formatFromTo(f, t);
};

Time.thisWeek = function (timezone, bizStartHour) {
    const f = _weekStart(timezone, bizStartHour);
    const t = moment.utc(f).add(1, 'week');
    return _formatFromTo(f, t);
};

Time.yesterday = function (timezone, bizStartHour) {
    const t = _dayStart(timezone, bizStartHour);
    const f = moment.utc(t).subtract(1, 'day');
    return _formatFromTo(f, t);
};

Time.today = function (timezone, bizStartHour) {
    const f = _dayStart(timezone, bizStartHour);
    const t = Time.now(timezone);
    return _formatFromTo(f, t);
};

// Parameters and methods for time-related funcionalities.
//
// Get the moment that the business day starts.
//
// For example, assume the business day starts at 5am. If the current time is
// 2016-02-01T09:00:00 (9am), the business day starts at 2016-02-01T05:00:00 (on the same day).
// If the current time is 2016-02-01T03:00:00 (3am), the business day start at
// 2016-01-31T05:00:00 (on the previous day).
//
//
// @param   {Date}     time          Could be Date, moment, or a ISO time string
// @param   {Integer}  bizStartHour  The hour business day starts
//
// @return  {Date}                 The time the business day starts
//
Time.bizDayStart = function (time, bizStartHour) {
    bizStartHour = bizStartHour || 0;
    return moment.utc(time).subtract(bizStartHour, 'hours')
        .startOf('day').add(bizStartHour, 'hours')
        .toDate();
};

// Get the moment that business hour starts on the day.
//
// For example, if a Date (new Date('2016-01-01T03:25:30')) is given, and the business start hour
// is 5, return moment.utc('2016-01-01T05:00:00').
//
// Note that for the same date (new Date('2016-01-01T03:25:30')), `bizDayStart` will return
// new Date('2015-12-31T05:00:00') instead.
//
// @param   {Date}     day           Could also be ISO string or moment
// @param   {Integer}  bizStartHour  Business starting hour
//
// @return  {moment}                 The time business day starts
//
Time.bizStartTimeOfDay = (day, bizStartHour) => moment.utc(day).startOf('day').add(bizStartHour, 'hours');

// Gets the difference between a given time and the bizDayStart of that time.
//
// Example:
//   timeInDay = 4am, bizStartHour = 5am -> duration = 23hr
//   timeInDay = 6am, bizStartHour = 5am -> duration = 1hr
Time.bizDayStartedDuration = function (timeInDay, bizStartHour, unit) {
    if (unit == null) { unit = 'second'; }
    const bizDayStart = Time.bizDayStart(timeInDay, bizStartHour);
    return moment(timeInDay).diff(bizDayStart, unit);
};

// Format the given `time` when gaps between points is `gapBetweenPoints`.
//
// This function is used to formulate tooltips on charts.
//
// `time` should be a Date object, `gapBetweenPoints` is one of ['hour', 'day', 'week', 'month']
//
Time.timeToText = function (time, gapBetweenPoints) {
    let format;
    if ((format = this.formats.human[gapBetweenPoints]) === undefined) {
        throw Error(`'${gapBetweenPoints}' is not a valid gap between points.`);
    } else {
        return moment.utc(time).format(format);
    }
};

Time.getLastYearOptions = function (thisYearDateRange, granularity) {
    const lastYearMatchType = (granularity !== undefined) && Granularity.largerOrEqualTo(granularity, 'Monthly')
        ? 'day'
        : 'weekday';
    const lastYearDateRange = thisYearDateRange.sameDateRangeLastYear(lastYearMatchType);

    return { lastYearMatchType, lastYearDateRange };
};

Time.sameDayLastYear = (day) => moment.utc(day).subtract(1, 'year').toDate();

Time.sameWeekdayLastYear = (time) => moment.utc(time).subtract(52, 'weeks').toDate();

Time.sameDayFromYear = (day, year) => {
    const target = moment.utc(day);
    const yearsAgo = target.diff(target.clone().year(year), 'years');
    return target.subtract(yearsAgo, 'years').toDate();
};

Time.sameWeekdayFromYear = (time, year) => {
    const target = moment.utc(time);
    const weeksAgo = target.diff(target.clone().year(year), 'weeks');
    return target.subtract(weeksAgo, 'weeks').toDate();
};

Time.timeRangeSpecs = {
    customized: {
        abbreviation: 'Customized',
        range() { throw Error('No range function for customized time range.'); },
        text: 'Customized'
    },
    lastYear: {
        abbreviation: 'LY',
        range: Time.lastYear,
        text: 'Last Year'
    },
    yearToDate: {
        abbreviation: 'YTD',
        range: Time.yearToDate,
        text: 'Year to Date',
        textShort: 'This Year'
    },
    last3Months: {
        abbreviation: '3M',
        range: Time.last3Months,
        text: 'Last 3 Months'
    },
    lastMonth: {
        abbreviation: '1M',
        range: Time.lastMonth,
        text: 'Last Month'
    },
    monthToDate: {
        abbreviation: 'MTD',
        range: Time.monthToDate,
        text: 'Month to Date',
        textShort: 'This Month'
    },
    fourteenDayForecast: {
        abbreviation: '2WF',
        range: Time.fourteenDayForecast,
        text: '14-Day Forecast'
    },
    lastWeek: {
        abbreviation: '1W',
        range: Time.lastWeek,
        text: 'Last Week'
    },
    weekToDate: {
        abbreviation: 'WTD',
        range: Time.weekToDate,
        text: 'Week to Date',
        textShort: 'This Week'
    },
    thisWeek: {
        abbreviation: 'W',
        range: Time.thisWeek,
        text: 'This Week'
    },
    yesterday: {
        abbreviation: 'Yesterday',
        range: Time.yesterday,
        text: 'Yesterday'
    },
    today: {
        abbreviation: 'Today',
        range: Time.today,
        text: 'Today'
    }
};

Time.timeRanges = _.keys(Time.timeRangeSpecs);

Time.getTimeRangeDisplayText = function (timeRange) {
    if (Array.from(Time.timeRanges).includes(!timeRange)) {
        throw new Error(`${timeRange} not in Time.timeRanges`);
    }
    return Time.timeRangeSpecs[timeRange].text;
};

Time.getTimeRangeAbbreviation = function (timeRange) {
    if (Array.from(Time.timeRanges).includes(!timeRange)) {
        throw new Error(`${timeRange} not in Time.timeRanges`);
    }
    return Time.timeRangeSpecs[timeRange].abbreviation;
};

// Get original "from" and "to" dates for the given time range. The return dates
// are API dates.
//
// opts:
// - timezone (String): Time zone, e.g., 'America/New_York'. If null, use browser timezone
// - bizStartHour (Number): Business day start hour, e.g., 5 means day starts at 5am
//
// return:
//   { fromDate: Date, toDate: Date }
//
Time.getTimeRangeFromToDates = function (timeRange, opts) {
    let fromDate; let
        toDate;
    if (opts == null) { opts = {}; }
    Assert.includes(this.timeRanges, timeRange);
    return ({ fromDate, toDate } = Time.timeRangeSpecs[timeRange].range(opts.timezone, opts.bizStartHour));
};


Time.getValidDate = function (date, minDate, maxDate) {
    date = _.max([minDate, date]);
    date = _.min([maxDate, date]);
    return date;
};


Time.getMinOfNowAndDate = function (timezone, date) {
    const now = Time.now(timezone).toDate();
    return _.min([now, date]);
};


// Given a date range, and a list of granularities, select ones that
// have at most <maxCount>, and minimum of 1 in that range.
Time.getGranularitiesForDateRange = function (fromDate, toDate, granularities, maxCount) {
    const granularities2count = _.fromPairs(_.map(granularities, (granularity) => {
        const count = Time.pointsBetween(fromDate, toDate, granularity);
        return [granularity, count];
    }));

    return _.filter(granularities, (granularity) => granularities2count[granularity] >= 1 && granularities2count[granularity] <= maxCount);
};


// If given granularity is in the list, then use it directly
// Otherwise, find granularity from the list that best matches the given count
Time.pickBestTableGranularity = function (fromDate, toDate, granularities, granularity, bestCount) {
    if (_.includes(granularities, granularity)) {
        return granularity;
    }
    return _.minBy(granularities, (granularity) => {
        const count = Time.pointsBetween(fromDate, toDate, granularity);
        return Math.abs(count - bestCount);
    });
};


Time.getValidBizDayStart = function (date, minDate, maxDate, bizStartHour) {
    // The first call to Time.getValidDate is needed in order to select a valid
    // day. The second call to Time.getValidDate is needed to select a valid
    // hour in that day.
    date = Time.getValidDate(date, minDate, maxDate);
    date = Time.bizDayStart(date, bizStartHour);
    date = Time.getValidDate(date, minDate, maxDate);

    return date;
};

// From an array of timeRanges, find ones that satisfy given conditions
// opts: { timezone, bizStartHour, ... }
// result: [ validTimeRange ]
Time.getValidTimeRanges = function (timeRanges, minDate, maxDate, opts) {
    if (opts == null) { opts = {}; }
    const timeRangeStrs = _.map(timeRanges, 'value');
    Assert.eachIsOneOf(timeRangeStrs, Time.timeRanges);
    const { timezone, bizStartHour } = opts;

    return _.map(timeRanges, (tr) => {
        const { fromDate, toDate } = Time.getTimeRangeFromToDates(tr.value, { timezone, bizStartHour });
        const valid = (
            (moment.utc(fromDate) < moment.utc(toDate))
            && (moment.utc(fromDate) < moment.utc(maxDate))
            && (moment.utc(fromDate) >= moment.utc(minDate))
        );
        if (valid) {
            return tr;
        }
        return _.extend({}, tr, {
            // Use `disabled: true` instead of `enabled: false` so that by default the option is enabled even if the bit is not set.
            disabled: true,
            disabledMessage: 'Not enough data for this range.'
        });
    });
};


// Convert a calendar to date to api to date.
//
// calendarToDate '2016-06-22' (bizStartHour @ 5am) -> apiToDate 2016-06-23 5am.
//
// apiToDate is 1 day ahead of calendarToDate, because if 2016-06-22 is the last
// selected day on calendar, all that business day's data needs to be loaded too.
//
Time.calendarToDateToApiToDate = (calendarToDate, bizStartHour) => Time.bizStartTimeOfDay(calendarToDate, bizStartHour).add(1, 'day').toDate();


// This is the opposite of calendarToDateToApiToDate
//
// Subtracting 1 second, because given apiToDate of
//
//   2016-06-22 0500 UTC + bizStartHour @ 5am,
//
// the calendarToDate should be
//
//   2016-06-21 0000 UTC
//
Time.apiToDateToCalendarToDate = (apiDate, bizStartHour) => moment.utc(Time.bizDayStart(moment.utc(apiDate).subtract(1, 'second'), bizStartHour)).startOf('day').toDate();


// Converts a biz date to calendar date.
//
// 2016-06-22 4am (bizStartHour @ 5am) -> 2016-06-21 12am
// 2016-06-22 6am (bizStartHour @ 5am) -> 2016-06-22 12am
//
Time.bizDateToCalendarDate = (bizDate, bizStartHour) => moment.utc(Time.bizDayStart(bizDate, bizStartHour)).startOf('day').toDate();


// Given Time and Day, return a Date that corresponds to
// given time in the business day.
//
// Example:
//   day: 2016-06-22, bizStartHour = 5, time = 5:30am
//   -> timeInBizDay = 2016-06-22 5:30am
//
//   day: 2016-06-22, bizStartHour = 5, time = 4:30am
//   -> timeInBizDay = 2016-06-23 4:30am
//
Time.timeInBizDay = function (time, day, bizStartHour) {
    const bizStart = Time.bizStartTimeOfDay(day, bizStartHour);
    const timeInDay = this.timeInDay(time, day);

    if (timeInDay < bizStart) {
        return moment(timeInDay).add(1, 'day').toDate();
    }
    return timeInDay;
};

// Here both day and time are javascript Date objects
// We take YYYY-MM-DD from day, and everything else from time, and put them
// together.
//
// Example:
//   day: 2016-06-22, time: 18:30:22
//   -> date: 2016-06-22 18:30:22
//
Time.timeInDay = function (time, day) {
    const timeInDayMmt = moment.utc(time);

    return moment.utc(day).set({
        hour: timeInDayMmt.hour(),
        minute: timeInDayMmt.minute(),
        second: timeInDayMmt.second()
    }).toDate();
};


const _r = require('./getApiPastFutureQueryParams');

Time.getApiPastFutureQueryParams = _r.getApiPastFutureQueryParams;
Time.getApiPastFutureQueryParamsForOccupancy = _r.getApiPastFutureQueryParamsForOccupancy;

Time.dateRangeApiFromToDates2fromToIso = function (dateRange) {
    const { apiFromDate, apiToDate } = dateRange;
    const from = Time.namedFormat(apiFromDate, 'iso');
    const to = Time.namedFormat(apiToDate, 'iso');
    return { from, to };
};


Time.weekdayOptionsMapping = {
    All: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    Weekday: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    Weekend: ['Saturday', 'Sunday'],
    Monday: ['Monday'],
    Tuesday: ['Tuesday'],
    Wednesday: ['Wednesday'],
    Thursday: ['Thursday'],
    Friday: ['Friday'],
    Saturday: ['Saturday'],
    Sunday: ['Sunday']
};

Time.weekdayOptions = _.keys(Time.weekdayOptionsMapping);


Time.getWeekdayDropdownOptions = () => _.map(Time.weekdayOptions, (option) => ({
    value: option,
    text: Time.getWeekdayOptionText(option),
    key: option
}));


Time.getWeekdayOptionText = function (weekdayOption) {
    const range = Time.weekdayOptionsMapping[weekdayOption];
    if (range == null) {
        throw Error(`Weekday option '${weekdayOption}' is not valid. Must be one of ${Time.weekdayOptions}.`);
    }

    if (range.length === 7) {
        return 'All Days';
    } if (range.length === 1) {
        return `${range[0]}s`;
    }
    return `${_.first(range)} - ${_.last(range)}`;
};


Time.dateMatchesWeekday = function (date, weekdayOption) {
    const weekday = moment(date).format('dddd');
    if (['All', null, undefined].includes(weekdayOption)) {
        return true;
    } if (Time.weekdayOptionsMapping[weekdayOption] != null) {
        return _.includes(Time.weekdayOptionsMapping[weekdayOption], weekday);
    }
    return weekday === weekdayOption;
};


Time.getWeekdayQueryValue = function (weekdayOption) {
    const range = Time.weekdayOptionsMapping[weekdayOption];
    return _.join((Array.from(range).map((r) => moment.utc().day(r).format('e'))), '');
};


Time.computeHourlyAverages = (series) => // series: [{ date, hour, value }]
//   date: instance of Date, used without actual time.
//   hour: hour of the day
//   value: the value used to compute averages
    _.map(_.groupBy(series, 'hour'), (values, hour) => ({
    // call `parseInt` in case `hour` is a string
        hour: parseInt(hour),

        value: Math.round(_.mean(_.map(values, 'value')))
    }));
