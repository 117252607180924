// @flow
// Used as a standard component for code splitting (only load a module's js code and css style when needed).
//
// Sample usage:
//
//   Foo = Loadable({
//     loader: () => import(/* webpackPrefetch: true */ './Foo')
//   })
//
import React from 'react';
import ReactLoadable from 'react-loadable';
import type { Options } from 'react-loadable';

import LoadingSpinner from 'shared/components/LoadingSpinner';
import NotFound404 from 'shared/components/NotFound404';

const Loadable = (options: Options) => (
    // WARNING object spread syntax doesn't work here.
    // eslint-disable-next-line prefer-object-spread
    ReactLoadable(Object.assign({ loading: Loading }, options))
);

export default Loadable;

type Props = {
  isLoading: boolean,
  pastDelay: boolean,
  timedOut: boolean,
  error: ?Error
};

const Loading = (props: Props) => {
    if (props.error || props.timedOut) {
        return <NotFound404 />;
    }
    if (props.isLoading && props.pastDelay) {
        return <LoadingSpinner />;
    }
    return null;
};
