/* eslint-disable
    func-names,
    no-multi-assign,
    no-param-reassign,
    no-var,
    prefer-const,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let ReactUtil;
module.exports = (ReactUtil = {});

const _ = require('lodash');
const Str = require('utils/str');

const _IGNORED_PROPS_IN_COMPARISON = ['key', 'ref'];


// Compares all keys present in two objs, return unequal ones.
//
// result: [ key ]
//
// By default all key values are compared shallowly. It can be changed by spec.
// Also, all functions are not compared and ignored.
//
// spec: {
//   deepCompare?: [ key ]
//     Keys that require deep comparison (otherwise shallow comparison is done)
//
//   ignore?: (pattern | [ pattern ])
//     Ignored keys. See ReactUtil.getAllKeys for detail of matching.
//
//   only?: [ key ]
//     Only compare keys in this list. <ignore> keys are still removed even if
//     they exist in this list.
// }
//
//
ReactUtil.getUnequalKeys = function (obj1, obj2, spec) {
    if (spec == null) { spec = {}; }
    const { deepCompare } = spec;

    const unEqualShallowCompare = _getShallowCompare(obj1, obj2, spec);
    const unEqualDeepCompare = _.filter(deepCompare, (key) => !_.isEqual(obj1[key], obj2[key]));

    return unEqualShallowCompare.concat(unEqualDeepCompare);
};


// spec: see ReactUtil.getUnequalKeys
ReactUtil.shallowEqualProps = function (obj1, obj2, spec) {
    if (spec == null) { spec = {}; }
    spec = _.extend({}, spec, { ignore: _IGNORED_PROPS_IN_COMPARISON.concat(spec.ignore != null ? spec.ignore : []) });

    const unEqualShallowCompare = _getShallowCompare(obj1, obj2, spec);
    return unEqualShallowCompare.length === 0;
};


// spec: see ReactUtil.getUnequalKeys
var _getShallowCompare = function (obj1, obj2, spec) {
    if (spec == null) { spec = {}; }
    const { deepCompare, ignore, only } = spec;

    let comparedKeys = ReactUtil.getAllKeys(obj1, obj2, { ignore });
    if (only != null) { comparedKeys = _.intersection(only, comparedKeys); }

    const shallowCompare = _.difference(comparedKeys, deepCompare);

    return _.filter(shallowCompare, (key) => {
        const [v1, v2] = Array.from([obj1[key], obj2[key]]);

        // Ignore functions
        if (_.isFunction(v1) || _.isFunction(v2)) {
            return false;

        // For certain primative types, pure javascript shallow comparison
        // doesn't work, so do value comparison instead.
        } if (v1 instanceof Date) {
            return !_.isEqual(v1, v2);
        }
        return v1 !== v2;
    });
};


// Get union of keys between two objs, removing unwanted keys.
//
// opts
// - ignore: See Str.matches() patterns
//
// result: [ key ]
//
ReactUtil.getAllKeys = function (obj1, obj2, opts) {
    if (opts == null) { opts = {}; }
    const { ignore } = _.defaults({}, opts, { ignore: [] });

    const allKeys = _.uniq(_.concat(Object.getOwnPropertyNames(obj1),
        Object.getOwnPropertyNames(obj2)));

    return _.filter(allKeys, (key) => !Str.matches(key, ignore));
};


// By default, compares all props and states with shallow comparison, and return
// true (for should update) and false (for shouldn't update).
//
// The spec modifies this behavior.
//
// spec: {
//   deepCompareProps?: [ propKey ]
//   ignoreProps?: [ propKey ]
//   onlyProps?: [ propKey ]
//
//   deepCompareStates?: [ stateKey ]
//   ignoreStates?: [ stateKey ]
//   onlyStates?: [ stateKey ]
// }
//
// result: { should: Bool, unEqualProps: [ propKey ], unEqualStates: [ stateKey ] }
//   unEqualProps and unEqualStates will contain unequal props and states if
//   should = true.
//
// Most of the testing of this function is done in getUnequalKeys function.
ReactUtil.shouldComponentUpdateBySpec = function (component, newProps, newState, spec) {
    if (newState == null) { newState = {}; }
    if (spec == null) { spec = {}; }
    let { props, state } = component;
    if (state == null) { state = {}; }

    let { deepCompareProps, ignoreProps, onlyProps,
        deepCompareStates, ignoreStates, onlyStates } = spec;

    ignoreProps = _IGNORED_PROPS_IN_COMPARISON.concat(ignoreProps != null ? ignoreProps : []);

    const unEqualProps = ReactUtil.getUnequalKeys(props, newProps,
        { deepCompare: deepCompareProps, ignore: ignoreProps, only: onlyProps });

    const unEqualStates = ReactUtil.getUnequalKeys(state, newState,
        { deepCompare: deepCompareStates, ignore: ignoreStates, only: onlyStates });

    const should = (unEqualProps.length > 0) || (unEqualStates.length > 0);

    return { should, unEqualProps, unEqualStates };
};
