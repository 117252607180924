/* eslint-disable
    func-names,
    no-param-reassign,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// DO NOT USE! DEPRECATED since March 21, 2018
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// Exports a single function, that takes a 'name' argument, and returns a log
// function. Purely syntactical sugar.
//
// Example:
// log = require('utils/log')('MyPage')
//
// log 'Message'
// --> 11/23/2016, 9:10:39 PM -- MyPage: Message
//
// log 'Something wrong', 'error'
// --> 11/23/2016, 9:10:39 PM -- MyPage: Something wrong         # Show up as error
//
const Misc = require('./misc');

module.exports = (name) => (function (msg, logType) {
    if (!logType) { logType = 'log'; }
    return Misc[logType](msg, { name });
});
