/* eslint-disable
    consistent-return,
    func-names,
    max-len,
    no-multi-assign,
    no-param-reassign,
    no-restricted-syntax,
    no-return-assign,
    no-unused-vars,
    no-var,
    prefer-const,
    prefer-rest-params,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Currently all http get requests are cached (using localStorage).
// Expiration setting can be adjusted globally and per request. See function
// signature of Http.get and Http.getAll for how to adjust expiration setting.
let Http;
module.exports = (Http = {});
const log = require('utils/log')('Http');

const _ = require('lodash');
const su = require('superagent');
const URI = require('urijs');

const Login = require('utils/login');
const SmkNotice = require('utils/smk_notice');
const Misc = require('utils/misc');
const Cache = require('utils/cache');
const LocalSettings = require('utils/local_settings');
const ErrorReport = require('utils/error_report');

// Cache is only enabled when CACHE_METHOD to set to 'smk'
// To disable caching (either in dev or prod), do this in browser console:
//
// > Smk.Utils.LocalSettings.set('http.cache_method', 'none')
//
// The setting will be preserved in the browser, across tabs (needs refresh).
//

Http.CACHE_METHOD = LocalSettings.get('http.cache_method') || 'smk';

const DURATION_HTTP_CACHE = 5 * 60; // Seconds

// Local setting overwrites global default
Http.DATA_CACHE_EXPIRATION = LocalSettings.get('duration.httpCache.data') || DURATION_HTTP_CACHE;


// Host will be appended to every request url. It allows specifying a different
// host than default.
//
// One usage example is integrated testing using production data: During
// testing, can point the host to production server, to consume real data.
//
const getLocalHost = () => LocalSettings.get('http.host') || '';
Http.getFullUrl = (url) => getLocalHost() + url;


const defaultOpts = {
    expiration: 0, // seconds
    timeout: 60, // seconds
    dontShowError: false,
    forceCallback: false
};


// Keep track of ongoing requests
Http._requests = [];

const _withDataMethods = ['POST', 'PUT', 'HEAD', 'DELETE'];
const _supportedMethods = _.concat(_withDataMethods, 'GET');


//= =================
// method: GET | POST | PUT | DELETE | HEAD
//
// opts (see defaultOpts for default values)
// - expiration: For cache
//   * Only meaningful when method is GET
// - timeout: Timeout for request
// - forceCallback: When true, callback will be invoked in the presence of an error
// - dontShowError: When true, SmkNotice.error will not be called
// - headers: Headers of request. If not present, will use default headers (see
//   _getHeaders)
// - query: Query string or object with key-value pairs of query parameters to be
//   sent with request. If this option is an object, a query string will be
//   appended to the URL with keys and values in no particular order. If any
//   value in the query object is an array, the values of the array will be added
//   as separate parameters with the same name. If url already has a query
//   string, the query parameters in url and in this option will be combined.
//
//= =================
// callback: (resultBody?, errmsg?, resp)
//
// - If no error, callback is passed (resultBody, undefined, resp)
//
// - If there's some error
//   - SmkNotice.httpError is called, unless opts.dontShowError is true
//   - If opts.forceCallback is true, then callback is passed (undefined, errmsg, resp)
//   - Else, callback is not called
//
Http.request = function (url, method, data, opts, callback) {
    let body;
    if (callback == null) { callback = undefined; }
    const fullUrl = URI(Http.getFullUrl(url)).addQuery(opts.query != null ? opts.query : {}).toString();

    if (!_.includes(_supportedMethods, method)) {
        throw Error(`Unsupported http method ${method}`);
    }

    opts = _.defaults(opts, defaultOpts);

    // If there's cache and not expired, use it directly.
    if (_canUseCache(method)) {
        body = Http.getCachedResult(fullUrl, opts.expiration);

        if (body) {
            log(`Using cache: ${fullUrl}`);
            setTimeout((() => Misc.attempt(callback, body)), 1); // Don't block original thread
            return;
        }
    }

    // Otherwise, send new request
    const headers = opts.headers || _getHeaders();

    const req = method === 'HEAD'
        ? su.head(fullUrl)
        : su(method, fullUrl);

    req
        .set(headers)
        .timeout(opts.timeout * 1000); // in ms

    if (opts.files != null) {
        for (const file of Array.from(opts.files)) {
            req.attach(file.name, file);
        }
    } else if (_.includes(_withDataMethods, method)) {
        req.send(data);
    }

    // Save requests, so it can be cleared when switching pages
    Http._requests.push(req);

    return req
        .end((err, resp) => {
            if (err || !resp.ok) {
                window.resp = resp;
                window.err = err;
                const errmsgs = _.pull([_.get(err, 'message') || _.get(resp, 'error.message'), _.get(resp, 'body.message')], undefined);
                const errmsg = errmsgs.join(', ');
                const errorDescription = `Error when calling ${method} ${fullUrl}: ${errmsg}`;
                log(errorDescription, 'error');

                if (!opts.dontShowError) {
                    if (shouldReportError(resp, url)) {
                        ErrorReport.report(Error(errorDescription), Http._requests[Http._requests.length - 1], resp);
                    }
                    if ((resp != null ? resp.status : undefined) != null) { // Is http error
                        SmkNotice.httpError(resp);
                    }
                }

                if (opts.forceCallback) {
                    return Misc.attempt(callback, undefined, errmsg, resp);
                }
            } else {
                // Cache results
                if (_canUseCache(method) && (opts.expiration > 0)) {
                    const key = _serializeHttpGetRequest(fullUrl, headers);
                    log(`Cached: ${fullUrl}`);

                    // Store response body, to save space.
                    try {
                        Cache.put(key, resp.body, { expiration: opts.expiration, namespace: _cacheNamespace });
                    } catch (e) {
                        // Maybe storage full
                        Http.cleanupCache(50);
                    }
                }

                return Misc.attempt(callback, resp.body, undefined, resp);
            }
        });
};


const NO_PERMISSION_ERROR_MESSAGE = "User doesn't have permission to access the resource";
var shouldReportError = (resp, url) => ((resp != null ? resp.statusCode : undefined) !== 500) && (__guard__(resp != null ? resp.body : undefined, (x) => x.message) !== NO_PERMISSION_ERROR_MESSAGE) && !url.match(/hooks.slack.com/);


// When two arguments: (url, callback)
// When three arguments: (url, opts, callback)
//
// opts: (see defaultOpts)
Http.get = function () {
    let [url, opts, callback] = Array.from(arguments);

    if (arguments.length === 2) {
        callback = opts;
        opts = {};
    }

    return Http.request(url, 'GET', undefined, opts, callback);
};

// Helper function. Same as Http.get in all aspects, except inserts an expiration
// into opts for loading data.
Http.getData = function () {
    let [url, opts, callback] = Array.from(arguments);

    if (arguments.length === 2) {
        callback = opts;
        opts = {};
    }

    _.defaults(opts, { expiration: Http.DATA_CACHE_EXPIRATION });

    return Http.get(url, opts, callback);
};

// Any cached data associated with non-get url will be expired immediately.
//
// When four arguments: (method, url, data, callback)
// When five arguments: (method, url, data, opts, callback)
const _requestWithData = function (method, url, data, opts, callback) {
    if (callback === undefined) {
        callback = opts;
        opts = {};
    }

    Http.request(url, method, data, opts, callback);
    return Http.resetCache(url);
};

// Http.post(), .put(), .head() have same signatures:
//
// When three arguments: (url, data, callback)
// When four arguments: (url, data, opts, callback)
Http.post = (url, data, opts, callback) => _requestWithData('POST', url, data, opts, callback);

Http.put = (url, data, opts, callback) => _requestWithData('PUT', url, data, opts, callback);

Http.head = (url, data, opts, callback) => _requestWithData('HEAD', url, data, opts, callback);

// When two arguments: (url, callback)
// When three arguments: (url, opts, callback)
Http.delete = function (url, opts, callback) {
    if (callback == null) { callback = undefined; }
    if (arguments.length === 2) {
        callback = opts;
        opts = {};
    }

    Http.request(url, 'DELETE', undefined, opts, callback);
    return Http.resetCache(url);
};

// When three arguments: (url, payload, callback)
// When four arguments: (url, payload, opts, callback)
Http.deleteWithPayload = function (url, payload, opts, callback) {
    if (callback == null) { callback = undefined; }
    if (arguments.length === 3) {
        callback = opts;
        opts = {};
    }

    Http.request(url, 'DELETE', payload, opts, callback);
    return Http.resetCache(url);
};

// callback is called with aggregated results, errmsgs, and resps
// once all requests have returned
const _batchRequest = function (urls, method, data, opts, callback) {
    const [results, errmsgs, resps] = Array.from(_.isArray(urls) ? [[], [], []] : [{}, {}, {}]);

    if (data == null) { data = {}; }
    let count = 0;

    if (_.isEmpty(urls)) {
        return Misc.attempt(callback, results, errmsgs, resps);
    }
    return _.map(urls, (url, key) => Http.request(url, method, data[key], opts, (result, errmsg, resp) => {
        results[key] = result;
        errmsgs[key] = errmsg;
        resps[key] = resp;
        count += 1;

        // Can't use results.length because [undefined, 1].length = 2
        if (count === _.size(urls)) {
            return Misc.attempt(callback, results, errmsgs, resps);
        }
    }));
};


// When two arguments: (urls, callback)
// When three arguments: (urls, opts, callback)
//
// urls: Array or Object.
// - When Array, format is [ url1, url2, ... ]
//   results will be array in same order: [ result1, result2, ...]
// - When Object, format is { key1: url1, key2: url2, ... }
//   results will be object { key1: result1, key2: result2, ... }
//
// callback: (results)
//
// opts: (see defaultOpts)
//
Http.getAll = function () {
    let [urls, opts, callback] = Array.from(arguments);

    if (arguments.length === 2) {
        callback = opts;
        opts = {};
    }

    return _batchRequest(urls, 'GET', undefined, opts, callback);
};

// Helper function. Same as Http.getAll in all aspects, except inserts an expiration
// into opts for loading data.
Http.getAllData = function () {
    let [urls, opts, callback] = Array.from(arguments);

    if (arguments.length === 2) {
        callback = opts;
        opts = {};
    }

    _.defaults(opts, { expiration: Http.DATA_CACHE_EXPIRATION });

    return Http.getAll(urls, opts, callback);
};

// When three arguments: (urls, data, callback)
// When four arguments: (urls, data, opts, callback)
//
// urls: same as Http.getAll
//
// data: Array or Object.
// - Has to be same as urls.
//
// callback: same as Http.getAll
//
Http.postAll = function (urls, data, opts, callback) {
    if (arguments.length === 3) {
        callback = opts;
        opts = {};
    }

    return _batchRequest(urls, 'POST', data, opts, callback);
};


Http.cancelAllRequests = function () {
    _.each(this._requests, (req) => req.abort());
    return this._requests = [];
};

// Serialization for caching
var _cacheNamespace = 'http';

var _serializeHttpGetRequest = (url, headers) => `url:${url}--headers:${uniqueStringify(headers)}`;

var uniqueStringify = (object) => JSON.stringify(_.sortBy(_.toPairs(object), _.first));

// Facilitates local debugging
Http.getCachedResult = function (url, expiration) {
    let body;
    if (expiration == null) { expiration = undefined; }
    const key = _serializeHttpGetRequest(this.getFullUrl(url), _getHeaders());
    return body = Cache.get(key, { expiration, namespace: _cacheNamespace });
};

Http.resetCache = function (url) {
    const key = _serializeHttpGetRequest(this.getFullUrl(url), _getHeaders());
    return Cache.remove(key, _cacheNamespace);
};

Http.cleanupCache = function (percentage) {
    if (percentage == null) { percentage = 100; }
    log('cleanupCache');
    return Cache.cleanupStorage({ percentage, namespace: _cacheNamespace });
};

// This can be used specify different auth token when doing http requests. One
// usage example is san diego widget, needs to use a different token than the
// regular login token.
//
Http.customAuthToken = undefined;
var _getHeaders = () => ({
    Authorization: `Bearer ${Http.customAuthToken || Login.getToken()}`
});


var _canUseCache = (method) => _.includes(['GET'], method)
    && (Http.CACHE_METHOD === 'smk')
    && Cache.isAvailable();

function __guard__(value, transform) {
    return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
