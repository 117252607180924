/* eslint-disable
    consistent-return,
    func-names,
    max-len,
    no-console,
    no-multi-assign,
    no-param-reassign,
    no-plusplus,
    no-shadow,
    react/no-deprecated,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// This module stores data related to all notifications, allowing components to subscribe
// to it and be notified when a notification data changes.
let SmkNotice;
module.exports = (SmkNotice = {});

const _ = require('lodash');
const Assert = require('utils/assert');
const Intercom = require('utils/intercom');
const { React } = require('common');

const { br, a } = React.DOM;

const defaultConfig = {
    clearAll: false,
    expiration: 5, // in seconds
    position: 'bottom'
};

const data = {
    messages: [], // message: { type, body, title, position, timeoutId }
    subscribers: [] // subscriber: { id, callback }
};

let idCount = 0;
const maxNotices = 5;

// Subscribe to SmkNotice to have a callback called when notification data changes.
// callback: should be a function
// returns an id that can be used to unsubscribe
SmkNotice.subscribe = function (callback) {
    const id = idCount++;
    data.subscribers.push({ id, callback });

    return id;
};

// Unsubscribe to SmkNotice to stop receiving changes in notification data.
// id is the same one that is returned from SmkNotice.subscribe.
SmkNotice.unSubscribe = (id) => _.remove(data.subscribers, { id });

SmkNotice.notify = () => _.forEach(data.subscribers, (subscriber) => subscriber.callback());

// Insert a new message in the messages array.
// type: should be one of 'success', 'info', 'warning' or 'error'
// opts:
//   clearAll: boolean, if true will clear all messages before inserting a new one
//   expiration: int, time in seconds before the message closes itself
//   position: string, it has to be 'top' or 'bottom', it represents where message
//     should be placed
SmkNotice.notice = function (type, body, title, opts) {
    let timeoutId;
    if (title == null) { title = undefined; }
    if (opts == null) { opts = {}; }
    Assert.includes(['success', 'info', 'warning', 'error'], type, 'notice');

    opts = _.defaults(opts, defaultConfig);
    const { clearAll, expiration, position } = opts;

    if (clearAll) {
        data.messages = [];
        _.forEach(data.timeouts, (timeoutId) => {
            clearTimeout(timeoutId);
            return true;
        });
    }

    const message = { type, body, title, position };

    if (expiration > 0) {
        timeoutId = setTimeout((() => {
            _.pull(data.messages, message);
            return this.notify();
        }
        ), expiration * 1000);

        message.timeoutId = timeoutId;
    }

    data.messages.unshift(message);

    _.forEach(data.messages, (message, idx) => {
        if ((idx >= maxNotices) && message.timeoutId) {
            return clearTimeout(message.timeoutId);
        }
    });

    data.messages = data.messages.slice(0, maxNotices);

    return this.notify();
};

SmkNotice.success = function (body, title, opts) {
    if (title == null) { title = undefined; }
    if (opts == null) { opts = {}; }
    return this.notice('success', body, title, opts);
};

SmkNotice.info = function (body, title, opts) {
    if (title == null) { title = undefined; }
    if (opts == null) { opts = {}; }
    return this.notice('info', body, title, opts);
};

SmkNotice.warning = function (body, title, opts) {
    if (title == null) { title = undefined; }
    if (opts == null) { opts = {}; }
    return this.notice('warning', body, title, opts);
};

SmkNotice.error = function (body, title, opts) {
    if (title == null) { title = undefined; }
    if (opts == null) { opts = {}; }
    opts = _.defaults(opts, {
        clearAll: true,
        expiration: 0
    });
    return this.notice('error', body, title, opts);
};

SmkNotice.clearNotices = function () {
    data.messages = [];
    _.forEach(data.timeouts, (timeoutId) => {
        clearTimeout(timeoutId);
        return true;
    });

    return this.notify();
};

SmkNotice.clearNotice = function (message) {
    _.pull(data.messages, message);
    return this.notify();
};

SmkNotice.httpError = function (err) {
    let message;
    if (err.status === undefined) { return; }

    const believe = "If you believe it's a system error, ";
    const persist = 'Please try again later. If the issue persists, ';
    const contactUs = a({
        key: '',
        onClick(e) {
            e.preventDefault();
            return Intercom.showNewMessage("Hey, there's an error on the dashboard. I want to see how fast your engineers can fix it.");
        }
    },

    'send us a message.');

    const newLine = br({ key: 'newLine' });
    let techDetail = `Technical detail: Status ${err.status}.`;
    if (err.error.message != null) { techDetail += ` Message: ${err.error.message}`; }
    if (!_.endsWith(techDetail, '.')) { techDetail += '.'; }

    if (err.status >= 500) {
        message = [persist, contactUs, newLine, techDetail];
    } else {
        message = [believe, contactUs, newLine, techDetail];
    }

    return console.error(message, 'Whoops! There was an error.');
};

SmkNotice.getMessagesFilteredByPosition = (position) => _.filter(data.messages, { position });
