/* eslint-disable
    func-names,
    no-multi-assign,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Store settings in localStorage
//
// Settings can be anything that needs to be sustained within a browser.
//
// All settings are stored in a single key in cache. To see all settings, type this in console:
//
// > Smk.LocalSettings.showAll()
//
let LocalSettings;
module.exports = (LocalSettings = {});

const _ = require('lodash');
const Cache = require('utils/cache');


const _cacheKey = 'local-settings';

LocalSettings.set = function (key, value) {
    try {
        const settings = this.getAll();
        _.set(settings, key, value);
        return Cache.put(_cacheKey, settings);
    } catch (e) {
        throw Error(e.message);
    }
};

LocalSettings.get = function (key) {
    const settings = this.getAll();
    return _.get(settings, key);
};

LocalSettings.getAll = function () {
    try {
        const settings = Cache.get(_cacheKey) || {};
        return settings;
    } catch (error) {
        return {};
    }
};

LocalSettings.showAll = function () {
    return JSON.stringify(this.getAll(), '', 2);
};
