// @flow
import _ from 'lodash';
import moment from 'moment';
import Http from 'react/utils/http';
import Time from 'react/utils/time';
import Garages from 'react/utils/garages';
import DateRange from 'react/utils/date_range';
import Localization from "shared/utils/localization";

export type PeakOccupancy = { peakTime: Date | null, value: number | null, [category: string]: number };

export type KeyStats = {
  peakOccupancy: PeakOccupancy,
  revenue: number | null,
  transactionCount: number | null,
  revenuePerTicket: number | null,
  transientRevenuePerTicket: number | null,
};

type OneYearStats = {
  start: Date,
  end: Date,
  keyStats?: KeyStats
};

export type BothYearsStats = { thisYear: OneYearStats, lastYear: OneYearStats };

export default (locationId: number, dateRange: DateRange, homeDimension: string, callback: (BothYearsStats) => void) => {
  const {apiFromDateLastYear, apiToDateLastYear} = getLastYearApiToFromDates(dateRange);
  const data = {
    thisYear: {
      start: dateRange.apiFromDate,
      end: dateRange.apiToDate
    },
    lastYear: {
      start: apiFromDateLastYear,
      end: apiToDateLastYear,
    }
  };

  _.each(['thisYear', 'lastYear'], (year: string) => {
    const y = data[year];
    getKeyStatsForTimePeriod(locationId, y.start, y.end, homeDimension, (keyStats: KeyStats) => {
      y.keyStats = keyStats;
      if (hasBothYearsData(data)) {
        callback(data);
      }
    });
  });
};

const getLastYearApiToFromDates = (dateRange: DateRange) => {
  const {lastYearMatchType, lastYearDateRange} = Time.getLastYearOptions(dateRange);
  const {apiFromDate, apiToDate} = lastYearDateRange;
  return {apiFromDateLastYear: apiFromDate, apiToDateLastYear: apiToDate};
};

const getKeyStatsForTimePeriod = (locationId: number, start: Date, end: Date, homeDimension: string, callback) => {
  const [transactionsRouteGrouped, revenueRouteGrouped] = _.map(['transactions', 'revenue'], (dataType) => (
    Garages.getUrlInBetweenFormat(locationId, 'past', dataType, start, end, {gb: homeDimension, currency: Localization.getCurrencyCode()})
  ));
  const occupancyRoute = Garages.getUrlInBetweenFormat(locationId, 'past', 'occupancy-breakdown/maximum', start, end);
  const routes = {
    occupancy: occupancyRoute,
    groupedTransactions: transactionsRouteGrouped,
    groupedRevenue: revenueRouteGrouped,
  };

  Http.getAllData(routes, {forceCallback: true}, (results) => {
    const {occupancy, groupedTransactions, groupedRevenue} = results;
    const peakOccupancy = occupancy
      ? {peakTime: moment.utc(occupancy.time).toDate(), value: 0}
      : {peakTime: null, value: null};

    if (occupancy && occupancy.value !== 0) {
      _.each(occupancy.value, (groupStat) => {
        peakOccupancy[groupStat.group] = groupStat.value;
        peakOccupancy.value += groupStat.value;
      });
    }

    const transactionCount = groupedTransactions ? Garages.getTotalGroupValue(getTotalForGroups(groupedTransactions)) : null;
    const revenue = groupedRevenue ? getTotalRevenueInCents(getTotalForGroups(groupedRevenue)) : null;

    const transientTransactionGroup = groupedTransactions ? _.find(groupedTransactions.value, (tt) => tt.group === 'Transient') : null;
    const transientRevenueGroup = groupedRevenue ? _.find(groupedRevenue.value, (tr) => tr.group === 'Transient') : null;
    const transientRevenuePerTicket = transientTransactionGroup && transientRevenueGroup
      ? (transientRevenueGroup.value / 100) / transientTransactionGroup.value : null;
    const revenuePerTicket = revenue && transactionCount && transactionCount !== 0
      ? revenue / transactionCount : null;

    callback({
      peakOccupancy,
      revenue,
      transactionCount,
      transientRevenuePerTicket,
      revenuePerTicket
    });
  });
};

const getTotalForGroups = (groupedStats) => (
  _.extend({}, groupedStats, {
    value: [_.reduce(groupedStats.value, (total, group) => (
      _.extend({}, total, {value: total.value + group.value})
    ), {group: 'Total', value: 0})]
  })
);

const getTotalRevenueInCents = (revenueInDollars: { value: Array<{ group: string, value: number }> }) => (
  Garages.getTotalGroupValue(revenueInDollars) / 100
);

const hasBothYearsData = (data: BothYearsStats) => data.thisYear.keyStats && data.lastYear.keyStats;
