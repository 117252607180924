/* eslint-disable
    consistent-return,
    func-names,
    max-len,
    no-multi-assign,
    no-shadow,
    no-unused-vars,
    no-var,
    prefer-const,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let GetMultiYearData;
module.exports = (GetMultiYearData = {});

const _ = require('lodash');
const DataGroups = require('models/data_groups');
const { getData, getDurationData } = require('./getData');

// Gets data from this year and last year, using similar apiFromDate and hours.
//
// If past year data is empty and this year is not empty, append groups to last
// year with all nulls. The group name will be same as the groups in this year's data.
//
// Params:
// - profile, pastDimension, dataType: See getData.
//
// callback: (thisYearDataGroups, thisYearTimes, lastYearDataGroups, hasLastYearData: Bool)
GetMultiYearData.getMultiyearData = function (profile, pastDimension, dataType, thisYearDateRange, lastYearDateRange, callback) {
    const results = {};

    getData(profile, pastDimension, dataType, thisYearDateRange, (thisYearDataGroups, thisYearTimes) => {
        _.extend(results, { thisYearDataGroups, thisYearTimes });
        return tryProcessDataGroups(results, (thisYearDataGroups, lastYearDataGroups, hasLastYearData) => callback(thisYearDataGroups, results.thisYearTimes, lastYearDataGroups, hasLastYearData));
    });

    return getData(profile, pastDimension, dataType, lastYearDateRange, (lastYearDataGroups) => {
        _.extend(results, { lastYearDataGroups });
        return tryProcessDataGroups(results, (thisYearDataGroups, lastYearDataGroups, hasLastYearData) => callback(thisYearDataGroups, results.thisYearTimes, lastYearDataGroups, hasLastYearData));
    });
};


GetMultiYearData.getMultiyearDurationData = function (locationId, timezone, pastDimension, thisYearDateRange, lastYearDateRange, queries, callback) {
    const results = {};

    getDurationData(locationId, timezone, pastDimension, thisYearDateRange.apiFromDate, thisYearDateRange.apiToDate, queries, (thisYearDataGroups) => {
        _.extend(results, { thisYearDataGroups });
        return tryProcessDataGroups(results, callback);
    });

    return getDurationData(locationId, timezone, pastDimension, lastYearDateRange.apiFromDate, lastYearDateRange.apiToDate, queries, (lastYearDataGroups) => {
        _.extend(results, { lastYearDataGroups });
        return tryProcessDataGroups(results, callback);
    });
};


var tryProcessDataGroups = function (results, onGroupsProcessed) {
    let { thisYearDataGroups, thisYearTimes, lastYearDataGroups } = results;
    if ((thisYearDataGroups != null) && (lastYearDataGroups != null)) {
        const hasLastYearData = lastYearDataGroups.hasGroups();
        if (!hasLastYearData && thisYearDataGroups.hasGroups()) {
            const groups = _.map(thisYearDataGroups.groups, (g) => ({
                name: g.name,
                values: _.fill(Array(g.values.length), null)
            }));
            lastYearDataGroups = new DataGroups(groups);
        }

        return onGroupsProcessed(thisYearDataGroups, lastYearDataGroups, hasLastYearData);
    }
};
