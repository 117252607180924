/* eslint-disable
    func-names,
    max-len,
    no-multi-assign,
    no-param-reassign,
    no-unused-vars,
    no-var,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let UserUtil;
module.exports = (UserUtil = {});
const _ = require('lodash');
const Http = require('react/utils/http');
const Garages = require('react/utils/garages');


UserUtil.getMiniMe = (callback) => Http.get('/api/users/v1/minimized-me', { dontShowError: true } , (miniMe) => callback(_getExpandedUser(miniMe)));

// IMPORTANT: This step is mandatory after every database loading of user
// object! It appends necessary default frontend setting not available in
// database, such as dashboard used by a user.
//
// result: expandedUser: { uiSettings, hidden, sanitized, garages,
//   dailyDigestGarageUrls, weeklyDigestGarageUrls, ... }
var _getExpandedUser = function (user) {
    const expandedUser = _.cloneDeep(user);
    if (_.isEmpty(expandedUser.uiSettings)) { expandedUser.uiSettings = {}; }
    _.defaultsDeep(expandedUser.uiSettings, {
        signup: {
            flag_event: false
        },
        permission: {}
    });
    _.defaults(expandedUser, {
        dailyDigestGarageUrls: [],
        garages: [],
        hidden: false,
        sanitized: false,
        weeklyDigestGarageUrls: [],
    });
    return expandedUser;
};

UserUtil.putMe = function (me, callback) {
    // The following route does not use garages list, omit it to avoid
    // transmitting unnecessarily large garges array (e.g. superAdmin)
    if (callback == null) { callback = undefined; }
    const miniMe = _.omit(me, 'garages');
    return Http.put('/api/users/v1/me', miniMe, callback);
};

UserUtil.getUser = (email, callback) => Http.get(`/api/users/v1/users/${email}`, (user) => callback(_getExpandedUser(user)));

UserUtil.getUsers = (callback) => Http.get('/api/users/v1/users', (result) => {
    const users = _.map(result.users, _getExpandedUser);
    return callback(users);
});

UserUtil.getUsersForGarage = (garageId, callback) => Http.get(`/api/users/v1/garages/${garageId}/users`, (result) => callback(result.users));

UserUtil.createUser = (user, callback) => Http.post('/api/users/v1/users', user, { forceCallback: true }, callback);

// all of baseUser data, excluding its email, is used in all post calls to create a new user.
// baseUser: { uiSettings, garages, dailyDigestGarageUrls, weeklyDigestGarageUrls }
//   garages: [ garage ]
//     garage: { url }
//   dailyDigestGarageUrls: [ url ]
//   weeklyDigestGarageUrls: [ url ]
UserUtil.createUsers = function (emails, baseUser, callback) {
    const urls = [];
    const data = [];
    _.each(emails, (email) => {
        const newUser = _.clone(baseUser);
        newUser.email = email;
        urls.push('/api/users/v1/users');
        return data.push(newUser);
    });

    return Http.postAll(urls, data, { forceCallback: true }, callback);
};

UserUtil.updateUser = function (user, callback) {
    // The following route only uses url, unicorn, and tag from garages, trim garages
    // list to avoid transmitting unnecessarily large garges array (e.g. superAdmin)
    const trimmedUser = _.cloneDeep(user);
    trimmedUser.garages = _.map(trimmedUser.garages, (garage) => _.pick(garage, ['url', 'unicorn', 'tag']));

    return Http.put(`/api/users/v1/users/${user.email}`, trimmedUser, { forceCallback: true }, (newUser, errmsg, resp) => callback(newUser, errmsg, resp));
};

UserUtil.deleteUser = (email, callback) => Http.delete(`/api/users/v1/users/${email}`, { forceCallback: true }, (conn, errmsg, resp) => callback(errmsg === undefined, errmsg, resp));

UserUtil.addLocationToUser = (userId, locationId, callback) => Http.post('api/users/v1/add-garage', { userId, garageId: locationId }, callback);

UserUtil.removeLocationFromUser = (userId, locationId, callback) => Http.delete(`api/users/v1/remove-garage/${userId}/${locationId}`, callback);

UserUtil.getNewUser = () => _getExpandedUser({
    email: '',
    garageAdmin: false,
    superAdmin: false,
    organizationType: 'UNSET', // This is the default value in the backend
});

UserUtil.getUserByActivationToken = function (token, callback) {
    const url = `/api/users/v1/activation/${token}`;
    return Http.get(url, { forceCallback: true }, (user, errmsg, resp) => {
        if (user) {
            return callback(_getExpandedUser(user), undefined, resp);
        }
        if (resp.statusCode === 404) {
            errmsg = 'Invalid token or the user is already activated.';
        }
        return callback(undefined, errmsg, resp);
    });
};

UserUtil.getUiSettings = function (uiSettings, path, defaultValue) {
    if (defaultValue == null) { defaultValue = undefined; }
    return _.get(uiSettings, path, defaultValue);
};

// TODO TB: This function mutates inputs
// Only updates in memory, not saved to backend
// DEPRECATED, user UserUtil.getUpdatedUiSettings instead
UserUtil.setUiSettings = (user, path, value) => _.set(user.uiSettings, path, value);

UserUtil.getUpdatedUiSettings = function (uiSettings, path, value) {
    uiSettings = _.cloneDeep(uiSettings);
    return _.set(uiSettings, path, value);
};
