// @flow
const getRoute = (subPath: string) => ({
    path: `/${subPath}/:token`,
    url: (token: string) => `/${subPath}/${token}`,
});

const preLoginRoutes = {
    activationRoute: getRoute('activate'),
    passwordResetRoute: getRoute('reset')
};

export default preLoginRoutes;
