/* eslint-disable
    func-names,
    max-len,
    no-multi-assign,
    no-return-assign,
    no-var,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let GetData;
module.exports = (GetData = {});
const _ = require('lodash');
const moment = require('moment');
const Group = require('react/models/group');
const DataGroups = require('react/models/data_groups');
const Calc = require('react/utils/calc');
const Http = require('react/utils/http');
const Time = require('react/utils/time');
const Garages = require('react/utils/garages');
const _combinePastFuture = require('./_combinePastFuture');
const Localization = require('shared/utils/localization').default;

// This function
//   1. Uses dateRange to determine past and future data ranges to query.
//   2. Sends past and/or future data queries to backend for data.
//   3. From the response, combines past and/or future data to format groups data.
GetData.getData = function (profile, pastDimension, dataType, dateRange, callback) {
    const { id, timezone } = profile;
    const { apiFromDate, apiToDate } = dateRange;
    const hours = moment(apiToDate).diff(apiFromDate, 'hours');
    const times = dataType === 'occupancy'
        ? Time.getApiPastFutureQueryParamsForOccupancy(apiFromDate, hours, timezone)
        : Time.getApiPastFutureQueryParams(apiFromDate, hours, timezone);

    const urls = {};
    const granularity = '1h'; // TODO (MK): Get granularity from profile when it is provided.
    _.each(times, (params, timeType) => {
        const queries = timeType === 'past' ? { gb: pastDimension } : {};
        if (dataType === 'revenue' && Localization.getCurrencyCode()) {
            queries.currency = Localization.getCurrencyCode();
        }
        return urls[timeType] = Garages.getDataUrl(id, timeType, dataType, params.start, params.hours, granularity, queries);
    });

    return getAllData(urls, times, pastDimension, (dataGroups) => callback(dataGroups, times));
};


GetData.getDurationData = function (locationId, timezone, pastDimension, apiFromDate, apiToDate, queries, callback) {
    const hours = moment(apiToDate).diff(apiFromDate, 'hours');
    const times = Time.getApiPastFutureQueryParams(apiFromDate, hours, timezone);
    const urls = {};
    _.each(times, (params, timeType) => urls[timeType] = Garages.getUrlInBetweenFormat(locationId, 'past', 'duration', apiFromDate, apiToDate, queries));

    return getPastData(urls, times, pastDimension, (dataGroups) => callback(dataGroups));
};

var getPastData = (urls, times, pastDimension, callback) => getDataFor(urls, times, pastDimension, callback, (pastResult, pastGroups) => {
    if (pastResult != null) {
        return [Garages.convertGroupsFormat(pastGroups), undefined];
    }
    throw Error('No result!');
});

var getAllData = (urls, times, pastDimension, callback) => getDataFor(urls, times, pastDimension, callback, (pastResult, pastGroups, futureResult, futureGroups) => {
    let futureTotal;
    if (pastResult && futureResult) {
        if (futureResult.dimension === pastDimension) {
            return [_combinePastFutureGroups(pastGroups, futureGroups, times), undefined];
        }
        if (futureResult.groups) { // Has data
            futureTotal = Group.sumAsOneGroup(futureGroups, { name: 'Total', includePartialSums: true });
            return _combinePastGroupsAndFutureTotalGroup(pastGroups, futureTotal, times.past.hours, times.future.hours);
        }
        const emptyFutureGroups = _generateEmptyGroups(_.map(pastGroups, 'group'), times.future.hours);
        return [_combinePastFutureGroups(pastGroups, emptyFutureGroups, times), undefined];
    } if (pastResult) {
        return [Garages.convertGroupsFormat(pastGroups), undefined];
    } if (futureResult) {
        if (futureResult.dimension === pastDimension) {
            return [Garages.convertGroupsFormat(futureGroups), undefined];
        }
        if (futureResult.dimension != null) {
            futureTotal = Group.sumAsOneGroup(futureGroups, { name: 'Total', includePartialSums: true });
            return [[], Garages.convertGroupFormat(futureTotal)];
        } // No data
        const emptyFutureTotalGroup = { name: 'Total', values: _.fill(Array(times.future.hours), null) };
        return [[], emptyFutureTotalGroup];
    }
    throw Error('No result!');
});

var getDataFor = (urls, times, pastDimension, callback, getGroupsAndTotalGroup) => Http.getAllData(urls, (results) => {
    const { past: pastResult, future: futureResult } = results;
    const pastGroups = pastResult != null ? pastResult.value : undefined;
    const futureGroups = futureResult != null ? futureResult.groups : undefined;
    const [groups, totalGroup] = Array.from(getGroupsAndTotalGroup(pastResult, pastGroups, futureResult, futureGroups));
    const dataGroups = new DataGroups(groups, totalGroup);
    return callback(dataGroups);
});


// Concat past and future groups. Fill missing group if it's missing in either past of future.
var _combinePastFutureGroups = function (pastGroups, futureGroups, times) {
    const names = _.union(_.map(pastGroups, 'group'), _.map(futureGroups, 'group'));
    return _.map(names, (name) => ({
        name,
        values: _combinePastFuture(pastGroups, futureGroups, name, times.past.hours, times.future.hours)
    }));
};


// Past data have group breakdowns. Future data has only one 'Total' group.
// Set future data for each breakdown group, and add a 'Total' group.
var _combinePastGroupsAndFutureTotalGroup = function (pastGroups, futureTotal, pastHours, futureHours) {
    const groups = _.map(pastGroups, (g) => ({
        name: g.group,
        values: _combinePastFuture(pastGroups, [], g.group, pastHours, futureHours)
    }));

    const pastTotalValues = pastGroups.length === 0
        ? _.fill(Array(pastHours), 0)
        : Calc.sumArrays(_.map(pastGroups, 'value'), { includePartialSums: true });

    const totalGroup = {
        name: 'Total',
        values: _.concat(pastTotalValues, futureTotal.value)
    };

    return [groups, totalGroup];
};

// Generate groups with given names, but filled with nulls
var _generateEmptyGroups = (names, count) => _.map(names, (name) => ({
    group: name,
    value: _.fill(Array(count), null)
}));
