/* eslint-disable
    consistent-return,
    func-names,
    no-console,
    no-multi-assign,
    no-param-reassign,
    no-return-assign,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Misc;
module.exports = (Misc = {});
const _ = require('lodash');
const Assert = require('react/utils/assert');
const Str = require('react/utils/str');

// Keep record of all logs, for debugging purpose
Misc.allLogs = [];

Misc.consoleNotice = function (type, msg, opts) {
    if (opts == null) { opts = {}; }
    Assert.includes(['error', 'warn', 'info', 'log', 'debug'], type);

    opts = _.defaults(opts,
        { name: '' });

    msg = `${new Date().toLocaleString()} -- ${opts.name}: ${msg}`;
    Misc.allLogs.push(`[${type.toUpperCase()}] ${msg}`);
    return console[type](msg);
};

Misc.log = function (msg, opts) { if (opts == null) { opts = {}; } return this.consoleNotice('log', msg, opts); };
Misc.debug = function (msg, opts) { if (opts == null) { opts = {}; } return this.consoleNotice('debug', msg, opts); };
Misc.warn = function (msg, opts) { if (opts == null) { opts = {}; } return this.consoleNotice('warn', msg, opts); };
Misc.info = function (msg, opts) { if (opts == null) { opts = {}; } return this.consoleNotice('info', msg, opts); };
Misc.error = function (msg, opts) { if (opts == null) { opts = {}; } return this.consoleNotice('error', msg, opts); };

// A common scenario met in many selectors: Given a list of options and an
// intended option, choose an appropriate one.
Misc.selectValidOption = function (options, intendedOption, defaultOptionOrFunc) {
    if (_.includes(options, intendedOption)) {
        return intendedOption;
    }
    if (_.isFunction(defaultOptionOrFunc)) {
        return defaultOptionOrFunc(options);
    }
    return defaultOptionOrFunc;
};

// A safer alternative to _.attempt, that will not swallow exceptions.
Misc.attempt = function (func, ...args) {
    if (func) {
        return func(...Array.from(args || []));
    }
};

// Given a single item, return [item]
// Given an array, return original array
Misc.makeArray = function (itemOrArray) {
    if (_.isArray(itemOrArray)) {
        return itemOrArray;
    }
    return [itemOrArray];
};

// Only compare keys specified by 'keys' for two objects.
// A generalization for lodash '_.isEqual' method,
// A key can be anything that can be used in '_.get' method.
//
// Example:
// obj1 = { a: { b: 1, c: 2 }, d: 3 }
// obj2 = { a: { b: 1, c: 3 }, d: 3 }
// isEqualForKeys(obj1, obj2, ['a.b', 'd']) -> true
//
// Notice that comparison for each key's value uses deep comparison
// (using _.isEqual):
//
// obj1 = { a: { b: { c: { d: 1 } } } }
// obj2 = { a: { b: { c: { d: 2 } } } }
// isEqualForKeys(obj1, obj2, ['a.b']) -> false
Misc.isEqualForKeys = function (obj1, obj2, keys) {
    let equal = true;
    keys = _.concat([], keys); // Make sure it's array
    _.each(keys, (key) => {
        const v1 = _.get(obj1, key);
        const v2 = _.get(obj2, key);

        if (!_.isEqual(v1, v2)) {
            return equal = false;
        }
    }); // Will also terminate loop early
    return equal;
};

// Checks if an object has all given paths. An extension to _.has method, which checks only one path.
// Mainly used for checking arguments in an options object.
Misc.hasAll = (obj, paths) => _.every(paths, (path) => _.get(obj, path) != null);

Misc.isValidJsonString = function (string) {
    try {
        JSON.parse(string);
    } catch (SyntaxError) {
        return false;
    }
    return true;
};
