// @flow
import assert from 'shared/lib/assert';
import type { User } from 'shared/FlowTypes';
import UserUtil from 'react/utils/user_util';
import Login from 'react/utils/login';

export const LOADED_USER = 'LOADED_USER';

export const LOGGED_OUT = 'LOGGED_OUT';

type LoadedUserAction = { type: 'LOADED_USER', user: User };
export const loadedUser = (user: User) => (
    { type: LOADED_USER, user }
);

type LoggedOutAction = { type: 'LOGGED_OUT' };
export const loggedOut = () => (
    { type: LOGGED_OUT }
);

type Action =
  | LoadedUserAction
  | LoggedOutAction;
type ThunkAction = (dispatch: Dispatch) => any;
type Dispatch = (action: Action | ThunkAction | Array<Action>) => any;

export const loadUser = (callback?: (user: User) => void) => (
    (dispatch: Dispatch) => {
        UserUtil.getMiniMe((user) => {
            assert(user.garages.length > 0, `User ${user.email} has no garages!`);
            Login.setupUser(user);
            if (callback) {
                callback(user);
            }
            dispatch(loadedUser(user));
        });
    }
);
